import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button', 'content'];

  initialize() {
    document.querySelectorAll('.inner-card').forEach((element) => {
      if (element != this.element) {
        element.remove()
      }
    })
  }

  connect() {
  }

  close() {
    this.element.remove()
  }
}