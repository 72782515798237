import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="reveal"
export default class extends Controller {
  connect() {}

  hide() {
    this.element.innerHTML = ''
  }
}
