import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return [ "button" ]
  }

	connect() {
		this.element[this.identifier] = this
	}

	doneLoading() {
		this.element.disabled = false
		this.buttonTarget.style.width = 'auto'
		this.buttonTarget.innerHTML = this.originalHTML
	}

	loading() {
		this.element.disabled = true
		this.originalHTML = this.buttonTarget.innerHTML // Store original HTML
		this.buttonTarget.innerHTML = this.element.dataset.loadingMessage
	}

	get originalHTML() {
		return this.original_html
	}

	set originalHTML(html) {
		this.original_html = html
	}

}
