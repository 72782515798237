import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="composer"
export default class extends Controller {
  static targets = ["photoId"];

  selectImage(event) {
    const imageElement = event.currentTarget;
    const unsplashId = imageElement.dataset.unsplashId;
    const photoIdInput = this.photoIdTarget;

    // 获取所有图片元素
    const allImages = document.querySelectorAll("[data-controller='composer'] img");

    // 移除先前选中的状态
    allImages.forEach((img) => {
      img.classList.remove("border");
      img.classList.remove("border-black");
      img.classList.remove("border-2");
    });

    imageElement.classList.toggle("border");
    imageElement.classList.toggle("border-black");
    imageElement.classList.toggle("border-2");

    photoIdInput.value = unsplashId;
  }
}
