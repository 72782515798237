import { Controller } from '@hotwired/stimulus';
import flatpickr from "flatpickr";
import dayjs from "dayjs"

export default class extends Controller {
  static values = { dayMode: Boolean }

  connect() {
    if (this.dayModeValue) {
      flatpickr(this.element, {
        dateFormat: "Y-m-d",
        defaultDate: 'today'
      })
    } else {
      const from = dayjs()
      const to = dayjs().add(3, 'day')

      flatpickr(this.element, {
        mode: "range",
        dateFormat: "Y-m-d",
        defaultDate: [from.toString(), to.toString()]
      })
    }
  }
}
