import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="autofill"
export default class extends Controller {
  static targets = ["authorInput"];

  fillAuthor(event) {
    this.authorInputTarget.value = "佚名";
  }
}
