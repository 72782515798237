import { Controller } from "@hotwired/stimulus"
import * as React from "react";
import { createRoot } from 'react-dom/client'
import Tipsoon from  "../components/tipsoon"

// Connects to data-controller="tipsoon"
export default class extends Controller {
  static values = { token: String, type: String, id: String }

  connect() {
    console.log(this.tokenValue)
    createRoot(this.element).render(<Tipsoon token={this.tokenValue}/>)
  }
}
