import { Controller } from "@hotwired/stimulus"
import * as React from "react";
import { createRoot } from 'react-dom/client'
import Editor from  "../components/editor"

// Connects to data-controller="editor"
export default class extends Controller {
  static values = { format: String }

  connect() {
    createRoot(this.element).render(<Editor format={this.formatValue || 'html'} />)
  }
}
