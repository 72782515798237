import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { observer } from "mobx-react-lite";
import useStore from "./stores/";
import { Dialog } from "@headlessui/react";
import _ from "lodash";

const ImageButton = observer((props) => {
  const fileInputRef = useRef();
  const [selectedFile, setSelectedFile] = useState();
  const { imageStore, postStore, uiStore } = useStore();
  const [selectedImage, setSelectedImage] = useState(null);
  const { post, update } = postStore;
  const { isDisable, submiting } = uiStore;
  const { images, saveUnsplash, uploadImages, getImages, page, uploading } = imageStore;

  let [isOpen, setIsOpen] = useState(false);
  let [showUnsplashForm, setShowUnsplashForm] = useState(false);
  let [unsplashUrl, setUnsplashUrl] = useState("");

  const handleOpen = async () => {
    await getImages();
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(false);
    setSelectedImage(null);
  };

  const handleSelected = (evt, image) => {
    Array.from(document.querySelectorAll(".media_picker_selected")).forEach(
      (el) => el.classList.remove("media_picker_selected")
    );
    evt.target.classList.toggle("media_picker_selected");

    setSelectedImage(image);
  };

  const handleConfirm = async () => {
    if (selectedImage) {
      await update(post.id, { image_ids: [selectedImage.id] });
      handleClose();
    }
  };

  const toggleUnsplash = () => {
    setShowUnsplashForm(!showUnsplashForm);
  };

  const closeUnsplash = () => {
    setShowUnsplashForm(false);
    setUnsplashUrl("");
  };

  const confirmUnsplash = async () => {
    await saveUnsplash(unsplashUrl);
    setUnsplashUrl("");
  };

  const uploadFromLocal = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async () => {
    await uploadImages(fileInputRef.current.files)
  };

  return (
    <>
      <button className="border px-2 rounded-md" onClick={handleOpen}>
        图片
      </button>
      <Dialog open={isOpen} onClose={handleClose} className="relative z-50">
        <div className="fixed inset-0 flex items-center justify-center p-4 bg-black/20">
          <Dialog.Panel className="w-full max-w-3xl rounded bg-white py-4 px-6">
            <Dialog.Title className="text-xl font-bold text-center">
              选择图片
            </Dialog.Title>
            <>
              <div className="flex justify-between">
                <div className="space-x-2">
                  <button
                    className="px-2 rounded-md bg-black text-white"
                    onClick={toggleUnsplash}
                  >
                    Unsplash
                  </button>
                  <button className="px-2 rounded-md bg-blue-600 text-white">
                    Wikipedia
                  </button>
                  <button
                    className={`px-2 rounded-md bg-red-600 text-white ${uploading ? "cursor-not-allowed" : ""}`}
                    disabled={uploading}
                    onClick={uploadFromLocal}
                  >
                    { uploading ? '上传中...' : '本地上传'}
                  </button>

                  <input
                    multiple={true}
                    accept="image/*"
                    className="hidden"
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    name="files"
                  />
                </div>
                <div>
                  <button
                    className={`px-2 rounded-md text-white ${
                      !_.isEmpty(selectedImage) ? "bg-black" : "bg-gray-600"
                    }`}
                    onClick={handleConfirm}
                  >
                    确认
                  </button>
                </div>
              </div>
              {showUnsplashForm && (
                <div className="my-2">
                  <div>
                    <input
                      value={unsplashUrl}
                      onChange={(evt) => {
                        setUnsplashUrl(evt.target.value);
                      }}
                      type="text"
                      className="border rounded-md px-2 py-1 w-full"
                      placeholder="https://unsplash.com/photos/..."
                    />
                  </div>
                  <div className="flex space-x-2 my-1">
                    <button
                      className="border px-2 rounded-md w-full"
                      onClick={closeUnsplash}
                    >
                      取消
                    </button>
                    <button
                      className={`px-2 rounded-md text-white w-full ${
                        _.isEmpty(unsplashUrl)
                          ? "bg-gray-600 cursor-not-allowed"
                          : "bg-black cursor-pointer"
                      }`}
                      disabled={_.isEmpty(unsplashUrl)}
                      onClick={confirmUnsplash}
                    >
                      {submiting ? "保存中..." : "保存"}
                    </button>
                  </div>
                </div>
              )}
              <div className="grid grid-cols-5 gap-4 py-4">
                {images.map((image, idx) => {
                  return (
                    <img
                      src={image.url || image.webp_url}
                      className="w-24 h-24 cursor-pointer"
                      onClick={(evt) => {
                        handleSelected(evt, image);
                      }}
                      key={idx}
                    />
                  );
                })}
              </div>
              <div className="flex justify-center space-x-2 text-sm">
                {page.prev > 0 && (
                  <button
                    className="bg-gray-100 hover:bg-gray-200 rounded-lg p-1"
                    onClick={() => {
                      getImages(page.prev);
                    }}
                  >
                    上一页
                  </button>
                )}
                {page.next > 0 && (
                  <button
                    className="bg-gray-100 hover:bg-gray-200 rounded-lg p-1"
                    onClick={() => {
                      getImages(page.next);
                    }}
                  >
                    下一页
                  </button>
                )}
              </div>
            </>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
});

export default ImageButton;
