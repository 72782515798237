import { Controller } from "@hotwired/stimulus"
import * as React from "react";
import { createRoot } from 'react-dom/client'
import PicturePicker from  "../components/picturePicker"

// Connects to data-controller="picture-picker"
export default class extends Controller {
  static values = { url: String, imageId: String, imageEle: String }
  connect() {
    createRoot(this.element).render(<PicturePicker url={this.urlValue} imageEle={this.imageEleValue} imageId={this.imageIdValue}/>)
  }
}