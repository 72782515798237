import { makeAutoObservable } from "mobx";

class UiStore {
  loading = true;
  submiting = false;
  isDisable = true;
  noMoreTask = false;
  notAuthorized = false;
  editMode = false;

  constructor(rootStore) {
    this.rootStore = rootStore;
    // 会把所有的属性变为 observable、所有的方法变为 action、所有 get 修饰的变为计算属性
    // autoBind true 表示绑定函数内部的 this 就是实例
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setLoading(val) {
    this.loading = val;
  }

  setSubmiting(val) {
    this.submiting = val
  }

  setIsDisable(val) {
    this.isDisable = val
  }

  setNoMoreTask(val) {
    this.noMoreTask = val
  }

  setEditMode(val) {
    this.editMode = val
  }
}

export default UiStore;

