import { Controller } from "@hotwired/stimulus"
import { destroy } from "@rails/request.js"

// Connects to data-controller="danger-confirm"
export default class extends Controller {
  static targets = ["button", "input"]

  static values = { url: String, name: String }

  connect() {}

  next(event) {
    // TODO: css 样式
    if (this.inputTarget.value === this.nameValue) {
      this.buttonTarget.disabled = false
    } else {
      this.buttonTarget.disabled = true
    }
  }

  async delete() {
    const resp = await destroy(this.urlValue)
    if (resp.ok) {
      const result = await resp.json
      if (result.ok) {
        window.location = result.redirect_to
        // TODO: window.toast success
      } else {
        // TODO: window. fail
      }
    }
  }
}
