import { Controller } from "@hotwired/stimulus"
import CodeMirror from "codemirror"

// Connects to data-controller="codemirror"
export default class extends Controller {
  connect() {
    const ele = document.getElementById("options_codemirror")

    CodeMirror.fromTextArea(ele, {
      value: "function myScript(){return 100;}\n",
      lineNumbers: true,
      theme: "tomorrow-night-bright",
      mode: {name: "javascript", json: true},
      lineWrapping: true,
      tabSize: 2
    });    
  }
}
