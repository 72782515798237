import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js";

// Connects to data-controller="wechat-share"
export default class extends Controller {
  static values = {
    inWechat: Boolean,
    pageLink: String
  }

  async connect() {
    console.log(this.inWechatValue)
    if (this.inWechatValue) {
      const url = window.location.href.split('#')[0]
      const title = document.querySelector('title').innerText.replace(/\n|\r/g, "")
      const desc = document.querySelector("meta[name='description']").getAttribute('content')
      const imageUrl = document.querySelector("meta[property='og:image']").getAttribute('content')

      const resp = await get(`/api/v1/wechat/share?url=${url}`, {
        contentType: 'application/json',
        responseKind: 'json',
      })

      if (resp.ok) {
        const data = await resp.response.json()
        console.log(data)
        wx.config({
          debug: false,
          appId: data.appid,
          timestamp: data.timestamp,
          nonceStr: data.noncestr,
          signature: data.signature,
          jsApiList: [
            'updateAppMessageShareData',
            'updateTimelineShareData'
          ]
        })
        wx.ready(function () {
          // 分享给朋友
          wx.updateAppMessageShareData({
            title: title, // 分享标题
            desc: desc, // 分享描述
            link: this.pageLinkValue || window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: imageUrl, // 分享图标
            success: function () {
              // 设置成功
              console.log("message success")
            }
          })

          // 分享到朋友圈、分享到QQ空间
          wx.updateTimelineShareData({
            title: title, // 分享标题
            link: this.pageLinkValue || window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: imageUrl, // 分享图标
            success: function () {
              // 设置成功
              console.log("timeline success")
            }
          })
        })
        wx.error(function (res) {
          console.log(res)
        })
      } else {
        console.log("sign error")
      }
    }
  }
}
