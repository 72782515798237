import { makeAutoObservable } from "mobx";
import { get, post, destroy, put } from "@rails/request.js";
import { toast } from "react-toastify";
import _ from "lodash";

class PostStore {
  post = {};
  reports = [];
  versions = [];
  currentVersion = {};

  constructor(rootStore) {
    this.rootStore = rootStore;
    // 会把所有的属性变为 observable、所有的方法变为 action、所有 get 修饰的变为计算属性
    // autoBind true 表示绑定函数内部的 this 就是实例
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setCurrentVersion(val) {
    this.currentVersion = val;
  }

  async getPost(id) {
    const payload = {
      headers: { Authorization: `Bearer ${this.rootStore.authStore.token}` }
    }

    if (id) {
      const resp = await get(`/censor/posts/${id}`, payload);
      if (resp.ok) {
        const result = await resp.json;
        this.post = result;
        this.loading = false;
      } else {
        if (resp.statusCode == 401) {
          this.notAuthorized = true;
          this.loading = false;
        } else if (resp.statusCode == 403) {
          const result = await resp.json;
          toast.error(result.message);
        } else {
          const result = await resp.json;
          toast.error(result.message);
        }
      }
    }
  }

  async getVersions(id) {
    const payload = {
      headers: { Authorization: `Bearer ${this.rootStore.authStore.token}` }
    }

    if (id) {
      const resp = await get(`/censor/posts/${id}/versions`, payload);
      if (resp.ok) {
        const result = await resp.json;
        this.versions = result;
        this.currentVersion = result[result.length - 1];
      } else {
        if (resp.statusCode == 401) {
          this.notAuthorized = true;
          this.loading = false;
        } else if (resp.statusCode == 403) {
          const result = await resp.json;
          toast.error(result.message);
        } else {
          const result = await resp.json;
          toast.error(result.message);
        }
      }
    }
  }


  async getReports(id) {
    const payload = {
      headers: { Authorization: `Bearer ${this.rootStore.authStore.token}` }
    }
    if (id) {
      const resp = await get(`/censor/posts/${id}/reports`, payload);
      if (resp.ok) {
        const result = await resp.json;
        this.rootStore.postStore.reports = result;
      } else {
        if (resp.statusCode == 401) {
          this.notAuthorized = true;
          this.loading = false;
        } else if (resp.statusCode == 403) {
          const result = await resp.json;
          toast.error(result.message);
        } else {
          const result = await resp.json;
          toast.error(result.message);
        }
      }
    }
  }

  async update(id, params) {
    const payload = {
      headers: { Authorization: `Bearer ${this.rootStore.authStore.token}` }
    }
    if (params) {
      payload.body = { post: params };
    }
    if (id) {
      const resp = await put(`/censor/posts/${id}`, payload);

      if (resp.ok) {
        const result = await resp.json;
        this.post = result;
        this.rootStore.uiStore.setEditMode(false);
        toast.success("修改成功");
      } else {
        if (resp.statusCode == 401) {
          this.notAuthorized = true;
          this.loading = false;
        } else if (resp.statusCode == 403) {
          const result = await resp.json;
          toast.error(result.message);
        } else {
          const result = await resp.json;
          toast.error(result.message);
        }
      }
    }
  }

  setPost(val) {
    this.post = val;
  }
}

export default PostStore;

