import { makeAutoObservable } from "mobx";
import { post } from "@rails/request.js";
import { toast } from "react-toastify";
import _ from "lodash";

class SeriesPostStore {
  notAuthorized = false;

  constructor(rootStore) {
    this.rootStore = rootStore;
    // 会把所有的属性变为 observable、所有的方法变为 action、所有 get 修饰的变为计算属性
    // autoBind true 表示绑定函数内部的 this 就是实例
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async create(seriesId, postIds) {
    const payload = {
      headers: { Authorization: `Bearer ${this.rootStore.authStore.token}` }
    }
    payload.body = {post_ids: postIds }
    payload.responseKind = 'turbo-stream'

    const resp = await post(`/series/${seriesId}/posts`, payload);
    if (resp.ok) {
    } else {
      if (resp.statusCode == 401) {
        this.notAuthorized = true;
      } else if (resp.statusCode == 403) {
        const result = await resp.json;
        toast.error(result.message);
      } else {
        const result = await resp.json;
        toast.error(result.message);
      }
    }
  }

}

export default SeriesPostStore;