import * as React from "react";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import useStore from './stores';
import Post from "./post3";
import queryString from 'query-string';

const PostForm = observer((props) => {
  const { sortingStore, authStore, postStore, uiStore } = useStore();
  const {submiting, isDisable} = uiStore;
  const {post, getPost, loading} = postStore;
  const {setToken} = authStore;

  useEffect(() => {
    let queries = queryString.parse(window.location.search)
    setToken(queries.token || props.token)

    getPost(props.id)
  }, [])

  const onSubmit = (e) => {
    const payload = { is_keep: answer.is_keep, qulaity: answer.qulaity, remark: answer.remark}
    answerTask(payload)
  }

  if (loading) {
    return <div className="flex items-center justify-center h-screen text-gray-500">加载中...</div>
  }

  return (
    <Post />
  );
});

export default PostForm;
