import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="image-select"
export default class extends Controller {
  static values = { fieldName: String }

  connect() {
    console.log(this.fieldNameValue)
    const pickerButton = document.getElementById("image-picker-button")
    const hidden_fields = document.getElementById("hidden_fields")
    if (hidden_fields) {
      if (hidden_fields.childElementCount >= 1) {
        pickerButton.classList.add("hidden")
      } else {
        pickerButton.classList.remove("hidden")
      }
    }

    // 移除清空标记
    const empty_images_flag = document.getElementById("empty_images")
    if (empty_images_flag) {
      empty_images_flag.remove()
    }
  }

  disconnect() {
    const hidden_fields = document.getElementById("hidden_fields")
    const pickerButton = document.getElementById("image-picker-button")
    if (hidden_fields) {
      if (hidden_fields.childElementCount === 0) {
        const input = document.createElement("input")
        input.type = "hidden"
        input.name = this.fieldNameValue
        input.autocomplete = "off"
        input.id = "empty_images"
        input.value = ""
        input.setAttribute('multiple', 'multiple')
        hidden_fields.appendChild(input)

        pickerButton.classList.remove("hidden")
      }
    }
  }
}
