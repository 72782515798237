import { Controller } from "@hotwired/stimulus"
import tippy, {roundArrow} from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light-border.css';

// Connects to data-controller="wechat-qrcode-tippy"
export default class extends Controller {
  static values = { url: String, name: String }
  connect() {
    console.log(this.urlValue)
    tippy('#wechat_account_qrcode', {
      content: `<div class="bg-white shadow-sm border rounded-md py-4 px-2 space-y-2 flex flex-col items-center justify-center"><img src="${this.urlValue}" class="rounded-sm w-20 h-20"/><div><h3 class="text-gray-500 text-xs text-center">打开微信扫一扫</h3><h3 class="text-gray-500 text-xs text-center">关注<b>${this.nameValue}</b></h3></div></div>`,
      allowHTML: true,
      arrow: true,
      placement: 'left',
      theme: 'light-border',
    });
  }
}
