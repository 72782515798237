import { makeAutoObservable } from "mobx";
import { get, post } from "@rails/request.js";
import { toast } from "react-toastify";
import _ from "lodash";

class FeaturedStore {
  loading = true;
  submiting = false;
  noMoreTask = false;
  notAuthorized = false;

  answer = {is_excellent: null, is_bootstrap: null};

  constructor(rootStore) {
    this.rootStore = rootStore;
    // 会把所有的属性变为 observable、所有的方法变为 action、所有 get 修饰的变为计算属性
    // autoBind true 表示绑定函数内部的 this 就是实例
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async popTask(pass_id = null) {
    this.loading = true;
    const payload = {
      headers: { Authorization: `Bearer ${this.rootStore.authStore.token}` }
    }
    if (pass_id !== null) { payload.query = { pass_id } }
    const resp = await get("/censor/featured/pop", payload);
    if (resp.ok) {
      this.loading = false;

      const result = await resp.json;
      this.rootStore.postStore.post = result;

      this.answer = {is_keep: null, qulaity: null, remark: null, reasons: []}
    } else {
      if (resp.statusCode == 400) {
        const result = await resp.json;
        if (result.type && result.type == "no_more_quiz") {
          this.loading = false;
          this.noMoreQuiz = true;
        }
      } else if (resp.statusCode == 401) {
        this.notAuthorized = true;
        this.loading = false;
      } else if (resp.statusCode == 403) {
        const result = await resp.json;
        toast.error(result.message);
      } else {
        const result = await resp.json;
        toast.error(result.message);
      }
    }
  }

  async answerTask(params) {
    this.submiting = true;
    const payload = {
      headers: { Authorization: `Bearer ${this.rootStore.authStore.token}` },
      body: params,
      contentType: "application/json",
    }

    const resp = await post(`/censor/featured/${this.rootStore.postStore.post.id}/answer`, payload);
    if (resp.ok) {
      this.submiting = false;
      this.popTask()
    } else {
      this.submiting = false;
      if (resp.statusCode == 401) {
        this.notAuthorized = true;
        this.loading = false;
      } else if (resp.statusCode == 403) {
        const result = await resp.json;
        toast.error(result.message);
      } else {
        const result = await resp.json;
        toast.error(result.message);
        if (result.type === 'already_done') {
          this.popTask()
        }
      }
    }
  }

  setAnswer(val) {
    this.answer = val;
  }
}

export default FeaturedStore;

