import { Controller } from "@hotwired/stimulus"
import debounce from "debounce"
import _ from 'lodash'

export default class extends Controller {
  static targets = ["input", "clearButton"]

  initialize() {
    this.submit = debounce(this.submit.bind(this), 300);
  }

  connect () {
    if (this.hasInputTarget && this.inputTarget.value == "") {
      this.clearButtonTarget.classList.add("hidden")
    }
  }

  submit(_event) {
    this.element.requestSubmit();
  }

  onChange(event) {
    if (_.isEmpty(this.inputTarget.value) || this.inputTarget.value === "") {
      this.clearButtonTarget.classList.add("hidden")
    } else {
      this.clearButtonTarget.classList.remove("hidden")
    }
  }

  clear(event) {
    this.inputTarget.value = ""
    this.clearButtonTarget.classList.add("hidden")
    window.location.href = window.location.href.split('?')[0]
  }
}