import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="openinstall"
export default class extends Controller {
  static values = { schemeUrl: String };

  connect() {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = '//web.cdn.openinstall.io/openinstall.js'

    const params = {data: {scheme_url: this.schemeUrlValue}, channelCode: ""}

    s.addEventListener('load',()=>{
      var data = OpenInstall.parseUrlParams();
      new OpenInstall({
          appKey : "ud2jjg",
          channelCode: "",
          // mask: function () {
          //   return "<div id='_shadow' style='position:fixed;left:0;top:0;background:rgba(0,255,0,0.5);filter:alpha(opacity=50);width:100%;height:100%;z-index:10000;'></div>"
          // },
          onready: function() {
              var m = this, button = document.getElementById("downloadButton");
              m.schemeWakeup(params);
              button.onclick = function() {
                  m.wakeupOrInstall(params);
                  return false;
              }
          }
      }, data);
    },false);

    document.head.appendChild(s);
  }
}