import * as React from "react";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import useStore from './stores';
import Post from "./post";
import queryString from 'query-string'

const Publisher = observer((props) => {
  const { publisherStore, postStore, authStore } = useStore();
  const {answer, loading, submiting, notAuthorized, noMoreQuiz, popTask, answerTask, setAnswer} = publisherStore;
  const {post} = postStore;
  const {setToken} = authStore;

  useEffect(() => {
    let queries = queryString.parse(window.location.search)
    setToken(queries.token || props.token)

    popTask()
  }, [])

  const onSubmit = (e) => {
    const payload = { clear_report: true, can_publish: answer.can_publish}
    answerTask(payload)
  }

  const handlePublish = (event) => {
    // setAnswer({...answer, can_publish: true})

    const payload = { clear_report: true, can_publish: true}
    answerTask(payload)
  }

  const handleNotPublish = (event) => {
    setAnswer({...answer, can_publish: false})
  }

  if (notAuthorized) {
    return <div className="flex items-center justify-center h-screen text-gray-500">权限验证失败</div>
  }

  if (loading) {
    return <div className="flex items-center justify-center h-screen text-gray-500">加载中...</div>
  }

  if (noMoreQuiz) {
    return (
      <div className="flex items-center justify-center h-screen w-full">
        <div className="flex-col items-center justify-center space-y-2">
          <div className="text-gray-500 text-center">暂无任务</div>
          <div className="text-center space-x-1">
            <button className="bg-black text-white rounded-md px-2 py-1/2 text-sm" onClick={() => {popTask()}}>重试</button>
            <button className="bg-black text-white rounded-md px-2 py-1/2 text-sm" onClick={() => { window.location.href = '/censor' }}>首页</button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <Post nextTask={() => {popTask(post.id)}} showReport={true} />
      <div className="rounded-md bg-slate-100 p-2 mt-2">
        <div className="text-md">现在发布吗?</div>
        <div className="text-sm space-x-2 flex my-2">
          <button onClick={handlePublish} className={`py-2 w-full border border-black rounded-md cursor-pointer hover:bg-black hover:text-white ${answer.can_publish === true ? 'bg-black text-white' : ''}`}>发布</button>
          <button onClick={handleNotPublish} className={`py-2 w-full border border-black rounded-md cursor-pointer hover:bg-black hover:text-white ${answer.can_publish === false ? 'bg-black text-white' : ''}`}>不发布</button>
        </div>
        {/* <button onClick={onSubmit} disabled={answer.can_publish === null || submiting} className={`py-2 w-full border rounded-md cursor-pointer ${answer.can_publish !== null && !submiting ? 'bg-black border-black text-white' : 'bg-gray-500 border-gray-500 text-white cursor-not-allowed'}`}>{submiting ? '提交中...' : '提交'}</button> */}
      </div>
    </>
  );
});

export default Publisher;
