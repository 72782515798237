import * as React from "react";
import { observer } from 'mobx-react-lite'
import useStore from '../stores'
import { Listbox } from '@headlessui/react'
import Editor from  "../../components/editor2"

const cardTypes = [
  {id: 1, name: "普通卡片", unavailable: false},
  {id: 2, name: "金句卡片", unavailable: false}
]

const EditForm = observer((props) => {
  const { postStore, authStore } = useStore()
  const { post, update } = postStore
  const [selectedCard, setSelectedCard] = React.useState(cardTypes[post.is_quote ? 1 : 0])
  const [title, setTitle] = React.useState(post.title || '')
  const [author, setAuthor] = React.useState(post.author || '')
  const [originalUrl, setOriginalUrl] = React.useState(post.original_url || '')

  const [format, setFormat] = React.useState(post.format)
  const [content, setContent] = React.useState(post.content)
  const [contentHtml, setContentHtml] = React.useState(post.content_html)
  const [contentJson, setContentJson] = React.useState(post.content_json)

  const { token } = authStore

  const onSubmit = () => {
    let payload = { author, content, format, original_url: originalUrl, content_html: contentHtml, content_json: contentJson }
    if (selectedCard.id === 2) {
      // 金句类型
      payload.is_quote = true
      payload.title = null
    } else {
      payload.title = title
    }

    update(post.id, payload)
  }

  const onUpdate = (editor) => {
    const formatInput = document.getElementById('post_format')
    setFormat(formatInput.value)

    if (formatInput.value === 'html') {
      setContent(editor.getText())
      setContentHtml(editor.getHTML())
      setContentJson(JSON.stringify(editor.getJSON()))
    } else if (formatInput.value === 'plain') {
      setContent(editor.getText())
      setContentHtml(null)
      setContentJson(JSON.stringify(editor.getJSON()))
    }
  }

  if (selectedCard.name === "金句卡片") {
    return (
      <div className="rounded-md bg-slate-100 p-1 space-y-1.5">
        <Listbox value={selectedCard} onChange={setSelectedCard}>
          <Listbox.Button className="border rounded-md text-sm p-2 w-full bg-white">{selectedCard.name}</Listbox.Button>
          <Listbox.Options className="bg-white">
            {cardTypes.map((card) => (
              <Listbox.Option key={card.id} value={card} disabled={card.unavailable} className="p-1 cursor-pointer hover:bg-yellow-50">
                {card.name}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Listbox>

        <div>
          <Editor format={post.format} contentPlain={post.content} contentHtml={post.content_html} contentJson={post.content_json} onUpdate={onUpdate} />
        </div>
        <div>
          <input placeholder="作者" type="text" name="author" value={author} onChange={(e) => {setAuthor(e.target.value)}} className="border rounded-md text-sm p-2 w-full" />
        </div>
        <div>
          <input placeholder="来源(选填)" type="text" name="original_url" value={originalUrl} onChange={(e) => {setOriginalUrl(e.target.value)}} className="border rounded-md text-sm p-2 w-full" />
        </div>
        <input value='plain' autoComplete="off" type="hidden" name="format" id="post_format" />
        <div>
          <button className="bg-black text-white py-2 rounded-md w-full" onClick={() => { onSubmit() }}>保存</button>
        </div>
      </div>
    )
  }

  return (
    <div className="rounded-md bg-slate-100 p-1 space-y-1.5">
      <Listbox value={selectedCard} onChange={setSelectedCard}>
        <Listbox.Button className="border rounded-md text-sm p-2 w-full bg-white">{selectedCard.name}</Listbox.Button>
        <Listbox.Options className="bg-white">
          {cardTypes.map((card) => (
            <Listbox.Option key={card.id} value={card} disabled={card.unavailable} className="p-1 cursor-pointer hover:bg-yellow-50">
              {card.name}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
      <div>
        <input placeholder="标题" type="text" name="title" value={title} onChange={(e) => {setTitle(e.target.value)}} className="border rounded-md text-sm p-2 w-full" />
      </div>
      <div>
        <input placeholder="作者" type="text" name="author" value={author} onChange={(e) => {setAuthor(e.target.value)}} className="border rounded-md text-sm p-2 w-full" />
      </div>
      <div>
        <Editor format={post.format} contentPlain={post.content} contentHtml={post.content_html} contentJson={post.content_json} onUpdate={onUpdate} />
      </div>
      <div>
        <input placeholder="来源(选填)" type="text" name="original_url" value={originalUrl} onChange={(e) => {setOriginalUrl(e.target.value)}} className="border rounded-md text-sm p-2 w-full" />
      </div>
      <div>
        <button className="bg-black text-white py-2 rounded-md w-full" onClick={() => { onSubmit() }}>保存</button>
      </div>

      <input value='html' autoComplete="off" type="hidden" name="format" id="post_format" />
    </div>
  )
})

export default EditForm
