import * as React from "react";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import useStore from './stores';
import Post from "./post";
import queryString from 'query-string';

const Imaging = observer((props) => {
  const { imageStore, authStore, postStore, uiStore } = useStore();
  const {notAuthorized, popTask} = imageStore;
  const {submiting, isDisable, noMoreTask, loading} = uiStore;
  const {post} = postStore;
  const {setToken} = authStore;

  useEffect(() => {
    let queries = queryString.parse(window.location.search)
    setToken(queries.token || props.token)

    popTask()
  }, [])

  if (notAuthorized) {
    return <div className="flex items-center justify-center h-screen text-gray-500">权限验证失败</div>
  }

  if (noMoreTask) {
    return <div className="flex items-center justify-center h-screen text-gray-500">暂无任务</div>
  }

  if (loading) {
    return <div className="flex items-center justify-center h-screen text-gray-500">加载中...</div>
  }

  return (
    <>
      <Post nextTask={() => {popTask(post.id)}} showImageButton={true} showKeywords={true}/>
      <div className="rounded-md bg-slate-100 p-2">
        <div className="text-sm space-x-2 flex mt-2">
          <button onClick={popTask} className="py-2 w-full border border-black rounded-md cursor-pointer hover:bg-black hover:text-white">下一个</button>
        </div>
      </div>
    </>
  );
});

export default Imaging;
