import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  initialize() {
    this.intersectionObserver = new IntersectionObserver(entries => this.processIntersectionEntries(entries))
  }

  connect() {
    this.intersectionObserver.observe(this.element)
  }

  disconnect() {
    this.intersectionObserver.unobserve(this.element)
  }

  processIntersectionEntries(entries) {
    entries.forEach(entry => {
      this.element.classList.toggle('opacity-0', entry.isIntersecting)
      this.element.classList.toggle('invisible', entry.isIntersecting)
    })
  }
}
