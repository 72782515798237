import { makeAutoObservable } from "mobx";
import { get, post } from "@rails/request.js";
import { toast } from "react-toastify";
import _ from "lodash";

class ImageStore {
  images = [];
  page = {}
  uploading = false
  notAuthorized = false;

  constructor(rootStore) {
    this.rootStore = rootStore;
    // 会把所有的属性变为 observable、所有的方法变为 action、所有 get 修饰的变为计算属性
    // autoBind true 表示绑定函数内部的 this 就是实例
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async getImages(page_number = 1) {
    const payload = {
      headers: { Authorization: `Bearer ${this.rootStore.authStore.token}` }
    }

    let query_params = {}
    query_params.page = page_number
    payload.query = query_params

    const resp = await get("/censor/images/list", payload);
    if (resp.ok) {
      const result = await resp.json;
      this.images = result.data
      this.page = result.page
    } else {
      if (resp.statusCode == 401) {
        this.notAuthorized = true;
      } else if (resp.statusCode == 403) {
        const result = await resp.json;
        toast.error(result.message);
      } else {
        const result = await resp.json;
        toast.error(result.message);
      }
    }
  }

  async uploadImages(files) {
    this.uploading = true

    const fd = new FormData();
    Array.from(files).forEach(file => {
      fd.append('files[]', file);
    });

    const payload = {
      headers: { Authorization: `Bearer ${this.rootStore.authStore.token}` },
      body: fd
    }

    const resp = await post('/censor/images', payload);
    if (resp.ok) {
      this.uploading = false
      const result = await resp.json;
      result.data.forEach((img) => {this.images.unshift(img)})
      if (result.errors.length > 0) {
        toast.warning(result.errors);
      } else {
        toast.success("上传成功");
      }
    } else {
      this.uploading = false
      if (resp.statusCode == 401) {
        this.notAuthorized = true;
      } else if (resp.statusCode == 403) {
        const result = await resp.json;
        toast.error(result.message);
      } else {
        const result = await resp.json;
        toast.error(result.message);
      }
    }
  }

  async saveUnsplash(url) {
    this.rootStore.uiStore.setSubmiting(true);
    const payload = {
      headers: { Authorization: `Bearer ${this.rootStore.authStore.token}` },
      body: {url},
      contentType: "application/json"
    }

    const resp = await post('/censor/images/unsplash', payload);
    if (resp.ok) {
      this.rootStore.uiStore.setSubmiting(false);
      const result = await resp.json;
      this.images = this.images.filter((img) => img.source_id !== result.source_id)
      this.images.unshift(result)
      toast.success("保存成功");
    } else {
      this.rootStore.uiStore.setSubmiting(false);
      if (resp.statusCode == 401) {
        this.notAuthorized = true;
      } else if (resp.statusCode == 403) {
        const result = await resp.json;
        toast.error(result.message);
      } else {
        const result = await resp.json;
        toast.error(result.message);
      }
    }
  }

  async popTask(pass_id = null, tipsoon_mode = false) {
    this.loading = true;
    const payload = {
      headers: { Authorization: `Bearer ${this.rootStore.authStore.token}` }
    }

    let query_params = {}
    if (pass_id) { query_params.pass_id = pass_id }
    if (tipsoon_mode) { query_params.tipsoon_mode = tipsoon_mode }
    payload.query = query_params

    const resp = await get("/censor/images/pop", payload);
    if (resp.ok) {
      this.rootStore.uiStore.setLoading(false);
      const result = await resp.json;
      this.rootStore.postStore.post = result;

    } else {
      if (resp.statusCode == 401) {
        this.notAuthorized = true;
      } else if (resp.statusCode == 403) {
        const result = await resp.json;
        toast.error(result.message);
      } else {
        const result = await resp.json;
        if (result.type == "no_more_quiz") {
          this.rootStore.uiStore.setNoMoreTask(true);
        } else {
          toast.error(result.message);
        }
        toast.error(result.message);
      }
      this.rootStore.uiStore.setLoading(false);
    }
  }
}

export default ImageStore;

