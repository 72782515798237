import { Controller } from "@hotwired/stimulus"
import { Sortable, Plugins } from '@shopify/draggable'
import { put } from "@rails/request.js"

// Connects to data-controller="series-sortable"
export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    this.sortable = new Sortable(this.element, {
      draggable: '.js-series-sortable-item',
      handle: '.js-series-sortable-item-handle',
      swapAnimation: {
        duration: 150,
        easingFunction: 'ease-in-out'
      },

      mirror: {
        appendTo: this.element,
        constrainDimensions: true
      },

      classes: {
        'source:dragging': 'is-dragging-placeholder',
        mirror: 'is-dragging'
      },

      plugins: [Plugins.SwapAnimation]
    })

    this.sortable.on('sortable:stop', this._reorderSeries)
  }

  disconnect () {
    if (this.sortable) {
      this.sortable.destroy()
    }
  }

  _reorderSeries = async (event) => {
    // const from_id = event.oldContainer.children[event.oldIndex].id.split('_')[1]
    // const to_id = event.newContainer.children[event.newIndex].id.split('_')[1]
    // console.log(from_id, to_id)

    await put(this.urlValue, {
      contentType: 'application/json',
      body: {
        from_position: event.oldIndex + 1,
        to_position: event.newIndex + 1
      }
    })
  }
}
