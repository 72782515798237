import * as React from "react";
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import CharacterCount from '@tiptap/extension-character-count'
import {PauseOne, Undo, Redo, Clear, Quote, Strikethrough, TextItalic, TextBold, OrderedList, ListTwo, Code, CodeBrackets, ParagraphAlphabet, ParagraphBreak, ClearFormat, PeopleSpeak} from '@icon-park/react'
import { RadioGroup } from '@headlessui/react'

const MenuBar = ({ editor }) => {
  const [isPlaying, setIsPlaying] = React.useState(false)

  if (!editor) {
    return null
  }

  const speak = (text) => {
    window.speechSynthesis.cancel()
    const utterance = new SpeechSynthesisUtterance(text)
    utterance.addEventListener('end', () => {
      setIsPlaying(false)
    });
    utterance.rate = 1.8
    window.speechSynthesis.speak(utterance)
    setIsPlaying(true)
  }

  const stopSpeak = () => {
    window.speechSynthesis.cancel()
    setIsPlaying(false)
  }

  return (
    <div className="space-x-1">
      <button type="button"
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className='menubar-btn'
      >
        <ListTwo theme="outline" className={editor.isActive('bulletList') ? 'text-blue-600' : ''} />
      </button>
      <button type="button"
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className='menubar-btn'
      >
        <OrderedList theme="outline" className={editor.isActive('orderedList') ? 'text-blue-600' : ''} />
      </button>
      <button type="button"
        onClick={() => editor.chain().focus().toggleBold().run()}
        className='menubar-btn'
      >
        <TextBold theme="outline" className={editor.isActive('bold') ? 'text-blue-600' : ''} />
      </button>
      <button type="button"
        onClick={() => editor.chain().focus().toggleItalic().run()}
        className='menubar-btn'
      >
        <TextItalic theme="outline" className={editor.isActive('italic') ? 'text-blue-600' : ''} />
      </button>
      <button type="button"
        onClick={() => editor.chain().focus().toggleStrike().run()}
        className='menubar-btn'
      >
        <Strikethrough theme="outline" className={editor.isActive('strike') ? 'text-blue-600' : ''} />
      </button>
      <button type="button"
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        className='menubar-btn'
      >
        <Quote theme="outline" className={editor.isActive('blockquote') ? 'text-blue-600' : ''}/>
      </button>
      <button type="button"
        onClick={() => editor.chain().focus().toggleCode().run()}
        className='menubar-btn'
      >
        <Code theme="outline" className={editor.isActive('code') ? 'text-blue-600' : ''} />
      </button>
      <button type="button"
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        className='menubar-btn'
      >
        <CodeBrackets theme="outline" className={editor.isActive('codeBlock') ? 'text-blue-600' : ''} />
      </button>
      <button type="button"
        onClick={() => editor.chain().focus().setParagraph().run()}
        className='menubar-btn'
      >
        <ParagraphAlphabet theme="outline" className={editor.isActive('paragraph') ? 'text-blue-600' : ''} />
      </button>
      <button type="button" className='menubar-btn' onClick={() => editor.chain().focus().setHardBreak().run()}>
        <ParagraphBreak theme="outline" />
      </button>
      <button type="button" className='menubar-btn' onClick={() => editor.chain().focus().unsetAllMarks().run()}>
        <ClearFormat theme="outline" />
      </button>
      <button type="button" className='menubar-btn' onClick={() => editor.chain().focus().clearNodes().run()}>
        <Clear theme="outline" />
      </button>
      <button type="button" className='menubar-btn' onClick={() => editor.chain().focus().undo().run()}>
        <Undo theme="outline" />
      </button>
      <button type="button" className='menubar-btn' onClick={() => editor.chain().focus().redo().run()}>
        <Redo theme="outline" />
      </button>
      <button type="button" className='menubar-btn' onClick={() => {speak(editor.getText())}}>
        <PeopleSpeak theme="outline" className={isPlaying ? 'text-blue-600' : ''}/>
      </button>
      {
        isPlaying && (
          <button type="button" className='menubar-btn' onClick={() => {stopSpeak()}}>
            <PauseOne theme="outline" />
          </button>
        )
      }

    </div>
  )
}

const Editor = (props) => {
  const [format, setFormat] = React.useState(props.format)
  const limit = 500

  const content_html = document.getElementById('post_content_html')
  const content_json = document.getElementById('post_content_json')
  const content = content_json.value === 'null' ? content_html.value : JSON.parse(content_json.value)

  const editor = useEditor({
    extensions: [
      StarterKit,
      CharacterCount.configure({
        limit,
      }),
      Placeholder.configure({
        placeholder: '分享新知...',
      }),
    ],
    content: content,
    onUpdate: ({editor}) => {
      const formatInput = document.getElementById('post_format')
      if (formatInput.value === 'html') {
        const content_plain = document.getElementById('post_content')
        const content_html = document.getElementById('post_content_html')
        const content_json = document.getElementById('post_content_json')

        content_plain.value = editor.getText()
        content_html.value = editor.getHTML()
        content_json.value = JSON.stringify(editor.getJSON())
      } else if (formatInput.value === 'plain') {
        const content_plain = document.getElementById('post_content')
        const content_html = document.getElementById('post_content_html')
        const content_json = document.getElementById('post_content_json')

        content_plain.value = editor.getText()
        content_html.value = null
        content_json.value = JSON.stringify(editor.getJSON())
      }
    }
  })

  const percentage = editor ? Math.round((100 / limit) * editor.storage.characterCount.characters()) : 0

  const handleFormatChange = (val) => {
    if (val === 'plain') {
      editor.chain().focus().selectAll().unsetAllMarks().clearNodes().blur().run()
    }

    setFormat(val)
    const formatInput = document.getElementById('post_format')
    formatInput.value = val
  }

  return (
    <div className="space-y-2">
      <RadioGroup value={format} onChange={handleFormatChange} className="flex text-xs space-x-1 cursor-pointer">
        <RadioGroup.Option value="html">
          {({ checked }) => (
            <span className={`${checked ? 'bg-gray-600 text-white ' : ''} px-2 py-1 rounded-md border`}>富文本</span>
          )}
        </RadioGroup.Option>
        <RadioGroup.Option value="plain">
          {({ checked }) => (
            <span className={`${checked ? 'bg-gray-600 text-white ' : ''} px-2 py-1 rounded-md border`}>纯文本</span>
          )}
        </RadioGroup.Option>
      </RadioGroup>
      <div>
        {format === 'html' && ( <MenuBar editor={editor} /> )}
        <EditorContent editor={editor} />
        {editor
          && <div className={`character-count ${editor.storage.characterCount.characters() === limit ? 'character-count--warning' : ''}`}>
            <svg height="20" width="20" viewBox="0 0 20 20" className="character-count__graph">
              <circle r="10" cx="10" cy="10" fill="#e9ecef"/>
              <circle
                r="5"
                cx="10"
                cy="10"
                fill="transparent"
                stroke="currentColor"
                strokeWidth="10"
                strokeDasharray={`calc(${percentage} * 31.4 / 100) 31.4`}
                transform="rotate(-90) translate(-20)"
              />
              <circle r="6" cx="10" cy="10" fill="white"/>
            </svg>

            <div className="character-count__text text-sm">
              {editor.storage.characterCount.characters()}/{limit} 个字符
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default Editor
