import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sidebar"
export default class extends Controller {
  static targets = [ "sidebarContainer" ]

  connect() {
    if (this.sidebarContainerTarget.dataset.expanded === "1") {
      this.expand()
    } else {
      this.collapse()
    }
  }

  toggle() {
    if (this.sidebarContainerTarget.dataset.expanded === "1") {
      this.collapse()
    } else {
      this.expand()
    }
  }

  collapse() {
    this.sidebarContainerTarget.classList.add("hidden")
    this.sidebarContainerTarget.dataset.expanded = "0"
  }

  expand() {
    this.sidebarContainerTarget.dataset.expanded = "1"
    this.sidebarContainerTarget.classList.remove("hidden")
  }
}
