import { makeAutoObservable } from "mobx";

class RemarkStore {
  remarks = [];
  remark = '';

  constructor(rootStore) {
    this.rootStore = rootStore;
    // 会把所有的属性变为 observable、所有的方法变为 action、所有 get 修饰的变为计算属性
    // autoBind true 表示绑定函数内部的 this 就是实例
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setRemarks(val) {
    this.remarks = val
  }

  setRemark(val) {
    this.remark = val
  }
}

export default RemarkStore;

