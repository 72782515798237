import { application } from '../javascript/controllers/application';

import DropdownComponentController from './dropdown_component_controller';
application.register('dropdown-component', DropdownComponentController);

import InnerCardComponentController from './inner_card_component_controller';
application.register('inner-card-component', InnerCardComponentController);

import ModalComponentController from './modal_component_controller';
application.register('modal-component', ModalComponentController);

import BackToTopComponentController from './back_to_top_component_controller';
application.register('back-to-top-component', BackToTopComponentController);