import { createContext, useContext } from 'react'
import { configure } from "mobx"
import AuthStore from './authStore'
import SortingStore from './sortingStore'
import PublisherStore from './publisherStore'
import PostStore from './postStore'
import AutocompleteStore from './autocompleteStore'
import PostSeriesStore from './postSeriesStore'
import SeriesPostStore from './seriesPostStore'
import ImageStore from './imageStore'
import FeaturedStore from './featuredStore'
import ProofreadStore from './proofreadStore'
import RemarkStore from './remarkStore'
import UiStore from './uiStore'

configure({
  enforceActions: "never",
})

class RootStore {
  constructor() {
    this.uiStore = new UiStore(this)
    this.authStore = new AuthStore(this)
    this.imageStore = new ImageStore(this)
    this.sortingStore = new SortingStore(this)
    this.publisherStore = new PublisherStore(this)
    this.postStore = new PostStore(this)
    this.featuredStore = new FeaturedStore(this)
    this.proofreadStore = new ProofreadStore(this)
    this.remarkStore = new RemarkStore(this)
    this.autocompleteStore = new AutocompleteStore(this)
    this.postSeriesStore = new PostSeriesStore(this)
    this.seriesPostStore = new SeriesPostStore(this)
  }
}

const Context = createContext(new RootStore())

export default function useStore() {
  return useContext(Context)
}
