import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "form", "button" ]

  toggle(event) {
    event.preventDefault()
    this.buttonTarget.textContent = this.buttonTarget.textContent === "回复" ? "取消回复" : "回复"
    this.formTarget.classList.toggle("hidden")
  }
}
