import * as React from "react";
import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import useStore from './stores/';
import Post from "./post";
import Post2 from "./post2";
import queryString from 'query-string';
import Remark from "./remark";
import { isEmpty } from "lodash";

const Proofread = observer((props) => {
  const { authStore, postStore, proofreadStore, uiStore } = useStore();
  const { moderators, currentModerator, setCurrentModerator, getModerators, setToken } = authStore;
  const { popTask, answer, setAnswer, answerTask, noMoreTask } = proofreadStore;
  const {submiting, isDisable} = uiStore;
  const { post, getVersions, versions, currentVersion, setCurrentVersion } = postStore;

  useEffect(() => {
    let queries = queryString.parse(window.location.search)
    setToken(queries.token || props.token)

    getModerators()
  }, [])

  useEffect(() => {
    popTask(currentModerator.value)
  }, [currentModerator])

  useEffect(() => {
    console.log("post changed get versions");
    getVersions(post.id)
  }, [post])

  handleModeratorsChange = (evt) => {
    const idx = evt.target.value
    setCurrentModerator(moderators[idx])
  }

  handleVersionsChange = (evt) => {
    const idx = evt.target.value
    setCurrentVersion(versions[idx])
    console.log(versions[idx])
  }

  onHaveIssue = () => {
    console.log("have issue")
    setAnswer({ has_issue: true })
  }

  onNoIssue = () => {
    console.log("no issue")
    setAnswer({ has_issue: false, remark: null })
  }

  const onRemarkChange = (val) => {
    setAnswer({...answer, remark: val})
  }

  const onSubmit = (e) => {
    const payload = { has_issue: answer.has_issue, remark: answer.remark}
    answerTask(payload)
  }

  return (
    <>
      <div className="flex justify-center space-x-1">
        <div className="w-full">
          <div className="bg-slate-100 rounded-md p-1 flex justify-between space-x-2">
            <span className="font-bold">当前小编：</span>
            <select onChange={handleModeratorsChange}>
              {moderators.map((moderator, idx) => {
                return <option key={idx} value={idx}>{moderator.label}</option>
              })}
            </select>
          </div>
          {
            noMoreTask ? <div className="bg-slate-100 rounded-md flex justify-center space-x-2 py-5 my-2">暂无内容</div>: <Post nextTask={() => {}} showReport={false} />
          }
        </div>
        <div className="w-full">
          {
            !isEmpty(versions) ? (
              <>
                <div className="bg-slate-100 rounded-md p-1 flex justify-between space-x-2">
                  <span className="font-bold">历史版本：</span>
                  <select onChange={handleVersionsChange} value={versions[versions.length - 1]}>
                    {versions.map((version, idx) => {
                      return <option key={idx} value={idx}>{version.name}</option>
                    })}
                  </select>
                </div>
                {currentVersion && <Post2 post={currentVersion.item} />}
              </>) : (<div className="bg-slate-100 rounded-md p-1 flex justify-center space-x-2 py-5">暂无内容</div>)
          }
        </div>
      </div>
      <div className="rounded-md bg-slate-100 p-2">
        <div className="text-md">内容有问题吗?</div>
        <div className="text-sm space-x-2 flex mt-2">
          <button onClick={onHaveIssue} className={`py-2 w-full border border-black rounded-md cursor-pointer hover:bg-black hover:text-white ${answer.has_issue == true ? 'bg-black text-white' : ''}`}>有</button>
          <button onClick={onNoIssue} className={`py-2 w-full border border-black rounded-md cursor-pointer hover:bg-black hover:text-white ${answer.has_issue == false ? 'bg-black text-white' : ''}`}>没有</button>
        </div>

      </div>
      {
        answer.has_issue === false && (
          <div className="mt-2">
            <button onClick={onSubmit} className={`py-2 w-full border border-black rounded-md cursor-pointer bg-black text-white bg-black border-black text-white`}>{submiting ? '提交中...' : '提交'}</button>
          </div>
        )
      }
      {
        answer.has_issue === true && (
          <>
            <Remark onRemarkChange={onRemarkChange}/>
            <button onClick={onSubmit} disabled={isDisable} className={`py-2 w-full border border-black rounded-md cursor-pointer bg-black text-white ${isDisable ? 'bg-gray-500 border-gray-500 text-white cursor-not-allowed' : 'bg-black border-black text-white'}`}>{submiting ? '提交中...' : '提交'}</button>
          </>
        )
      }
    </>
  );
});

export default Proofread;
