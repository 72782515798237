import { Controller } from "@hotwired/stimulus"
import * as React from "react";
import { createRoot } from 'react-dom/client'
import PostForm from  "../components/post_form"

// Connects to data-controller="edit-post"
export default class extends Controller {
  static values = { id: String, token: String }

  connect() {
    createRoot(this.element).render(<PostForm id={this.idValue} token={this.tokenValue}/>)
  }
}
