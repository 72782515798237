import { Controller } from "@hotwired/stimulus"
import {get} from '@rails/request.js'

// Connects to data-controller="hovercard"
export default class extends Controller {
  static targets = ["card"];
  static values = { url: String };

  connect() {
  }

  async show() {
    if (this.hasCardTarget) {
      this.cardTarget.classList.remove("hidden");
    } else {
      this.cardTargets.forEach(card => {
        card.classList.remove("hidden")
      })

      const response = await get(this.urlValue)
      if (response.ok) {
        const html = await response.html

        const fragment = document.createRange().createContextualFragment(html)
        this.element.appendChild(fragment)
      }
    }
  }

  hide(){
    if (this.hasCardTarget) {
      this.cardTarget.classList.add("hidden");
    }
  }

  disconnect() {
    if (this.hasCardTarget) {
      this.cardTarget.remove();
    }
  }
}