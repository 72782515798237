import { Controller } from "@hotwired/stimulus"
import * as React from "react";
import { createRoot } from 'react-dom/client'
import Publisher from  "../components/publisher"

// Connects to data-controller="publisher"
export default class extends Controller {
  static values = { token: String }

  connect() {
    createRoot(this.element).render(<Publisher token={this.tokenValue}/>)
  }
}
