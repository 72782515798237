import { Controller } from "@hotwired/stimulus"
import * as React from "react";
import { createRoot } from 'react-dom/client'
import AddToSeriesButton from "../components/addToSeriesButton"

// Connects to data-controller="add-to-series"
export default class extends Controller {
  static values = { postId: String }

  connect() {
    createRoot(this.element).render(<AddToSeriesButton postId={this.postIdValue}/>)
  }
}
