import * as React from "react";
import { useState } from "react";

import { observer } from 'mobx-react-lite'
import useStore from './stores'
import _ from "lodash";

let isComposition = false
const isChrome = navigator.userAgent.indexOf('Chrome') > -1

const Remark = observer((props) => {
  const { uiStore, remarkStore } = useStore();
  const {setIsDisable, isDisable} = uiStore;
  const [shortcuts, setShortcuts] = useState(props.shortcuts || ['建议删除', '特殊节假日', '内容不完整', '内容无意义', '涉政涉黄', '排版问题', '引流涨粉', '音乐类型'])
  const {remarks, setRemarks, remark, setRemark} = remarkStore

  handleComposition = (ev) => {
    if (ev.type === 'compositionend') {
      isComposition = false
      if (!isComposition && isChrome) {
        handleRemarkChange(ev)
      }
    } else {
      isComposition = true
    }
  }

  const handleClick = (val) => {
    if (_.includes(remarks, val)) {
      if (remarks.length > 1) {
        setIsDisable(false)
      } else {
        if (remark.length == 0) {
          setIsDisable(true)
        }
      }
      setRemarks(remarks.slice(0, remarks.length - 1))
    } else {
      setRemarks([...remarks, val])
      setIsDisable(false)
    }
    if (props.onRemarkChange) {
      let val = ""
      if (!_.isEmpty(remarks)) {
        val += remarks.join('、')
      }
      if (!_.isEmpty(remark)) {
        if (!_.isEmpty(remarks)) {
          val += '，' + remark
        } else {
          val += remark
        }
      }
      props.onRemarkChange(val)
    }
  }

  const handleRemarkChange = (event) => {
    if (!isComposition) {
      if (event.target.value.length > 0) {
        setIsDisable(false)
      } else {
        if (remarks.length == 0) {
          setIsDisable(true)
        }
      }

      setRemark(event.target.value)
      if (props.onRemarkChange) {
        let val = ""
        if (!_.isEmpty(remarks)) {
          val += remarks.join('、')
        }
        if (!_.isEmpty(remark)) {
          if (!_.isEmpty(remarks)) {
            val += '，' + remark
          } else {
            val += remark
          }
        }
        props.onRemarkChange(val)
      }
    }
  }

  return (
    <div className="rounded-md bg-slate-100 p-2 mt-2">
      <div className="text-md">请展开说说原因：</div>
      {!_.isEmpty(shortcuts) && (
        <div className="my-2 flex justify-center">
          {shortcuts.map((s, idx) => {
            return <span key={idx} className={`cursor-pointer px-2 py-1 rounded-md bg-gray-200 mx-1 ${_.includes(remarks, s) ? 'bg-green-300' : ''}`} onClick={() => {handleClick(s)}}>{s}</span>
          })}
        </div>
      )}
      <div className="mt-2">
        <textarea
          rows={2}
          className="placeholder:text-slate-400 w-full border rounded-md p-2"
          placeholder="详细说明(选填)"
          onChange={handleRemarkChange}
          onCompositionStart={handleComposition}
          onCompositionEnd={handleComposition}
        />
      </div>
    </div>
  )
})

export default Remark;
