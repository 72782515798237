import * as React from "react";
import { observer } from 'mobx-react-lite'
import ReportButton from "./reportButton"
import SpeakButton from "./speakButton"
import useStore from './stores'
import { isEmpty } from "lodash";
import EditForm from "./posts/edit2";

const EditButton = observer((props) => {
  const { postStore, uiStore } = useStore()
  const {editMode, setEditMode} = uiStore

  const handleEditToggle = () => {
    setEditMode(!editMode)
  }

  return (
    <button className="border px-2 rounded-md" onClick={handleEditToggle}>{editMode ? '退出编辑' : '编辑'}</button>
  )
})

const Post = observer((props) => {
  const { postStore, authStore, uiStore } = useStore()
  const {post, reports, getReports} = postStore
  const {token} = authStore
  const {editMode, setEditMode} = uiStore
  const [showReport, setshowReport] = React.useState(props.showReport || false)

  React.useEffect(() => {
    getReports(post.id)
  } , [post.id])

  const goHome = () => {
    window.location.href = `/censor?token=${token}`
  }

  const handleNext = async () => {
    props.nextTask()
  }

  return (
    <div className="my-2">
      <div className="rounded-md bg-slate-100 p-1 my-2 flex justify-between items-center">
        <div>
          {/* <button className="border px-2 rounded-md" onClick={goHome} >首页</button> */}
        </div>
        <div className="flex justify-end space-x-1">
          <SpeakButton />
          <EditButton />
          {/* <ImageButton /> */}
          {/* <button className="border px-2 rounded-md" onClick={() => (window.open(`/posts/${post.id}`, '_blank'))}>预览</button> */}
          {/* <button className="border px-2 rounded-md" onClick={() => (window.open(`/admin/posts/${post.id}/edit`, '_blank'))}>编辑</button> */}
          <ReportButton type='Post' id={post.id} token={token} />
          {/* <NextButton type='Post' id={post.id} token={token} nextTask={handleNext} /> */}
        </div>
      </div>


      <div className="rounded-md space-y-2">
        {
          (post.images && post.images.length > 0) && (
            <div className="flex justify-center items-center relative h-[260px] md:h-[400px] w-full overflow-hidden rounded-md">
            <img  className="z-0 absolute left-0 top-0 object-cover w-full" src={`${post.images[0].gaussblur_url || post.images[0].url}`} />
            <img  className="h-[260px] md:h-[400px] absolute" src={`${post.images[0].webp_url || post.images[0].url}`} />
          </div>
          )
        }

        {
          editMode ? <EditForm /> : (
            <div className="rounded-md bg-slate-100 p-1">
              <div className="font-bold text-lg">{post.title}</div>
              <div className="text-xs text-gray-600 flex items-center justify-between">
                <span>{post.author_s}</span>
                <span className="space-x-1">
                  {!isEmpty(post.source_from) && (<span className="border rounded-md px-2">{post.source_from[0]}</span>)}
                  <span className="border rounded-md px-2">{post.format == 'plain' ? '纯文本' : '富文本'}</span>
                  {post.is_quote ? <span className="border rounded-md px-2">金句</span> : <span className="border rounded-md px-2">普通</span>}
                </span>
              </div>
              {(post.format === 'plain') && (<div className="text-md text-black/90" style={{whiteSpace: "pre-wrap"}}>{post.content}</div>)}
              {(post.format === 'html') && (<div className="octopus-content" dangerouslySetInnerHTML={ { __html: post.content_html}} ></div>)}
            </div>
          )
        }

        {
          !isEmpty(post.remark) && (
            <div className="rounded-md bg-orange-100 p-1 space-y-0.5 text-xs text-orange-600">
              <span className="font-bold">备注：</span>
              {post.remark}
            </div>
          )
        }
        {/* {
          !isEmpty(post.metadata) && (
            <div className="rounded-md bg-purple-100 p-1 space-y-0.5 text-xs text-purple-600">
              <ul>
                {
                  Object.entries(post.metadata).map(([key, value], idx) => {
                    if (key === 'cover') {
                      return <li key={idx}><img src={value} className="w-20 h-20"/></li>
                    } else {
                      return <li key={idx} className="x-1"><b>{key}: </b>{value}</li>
                    }

                  })
                }
              </ul>
            </div>
          )
        } */}
        {
          (!isEmpty(reports) && showReport) && (
            <div className="rounded-md bg-yellow-100 p-1 space-y-0.5">
              {
                reports.map((report, idx) => {
                  return (
                    <div key={idx} className="border border-yellow-200 rounded-md p-1">
                      <div className="text-xs font-bold text-yellow-800">{report.reason}</div>
                      <div className="text-xs text-gray-500">{report.remark}</div>
                    </div>
                  )
                })
              }
            </div>
          )
        }
      </div>

    </div>
  )
})

export default Post;
