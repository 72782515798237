import * as React from "react";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Dialog } from "@headlessui/react";
import { isEmpty } from "lodash";
import { post } from "@rails/request.js";
// TODO: 涉嫌侵权

const ReportButton = observer((props) => {
  const topMenus = [
    {
      name: "不实信息",
      submenus: [
        {
          name: "科学类不实信息",
          requireRemark: true,
          tips: "知乎鼓励专业的回答，请在举报时提供内容中与当前学术共识或权威研究结论相左的错误或不准确部分，或通过权威资讯指出其中错误，感谢你的支持。点击了解更多社区规范。",
        },
        {
          name: "编造经历类不实信息",
          requireRemark: true,
          tips: "知乎禁止对标榜真实经历的内容进行恶意夸大或过分虚构的行为。请在举报时提供被举报内容中编造经历的佐证，如事实错误、前后矛盾等相关内容段落或其他详细信息。感谢你与我们共同维护知乎真实的内容环境。",
        },
        {
          name: "社会谣言类不实信息",
          requireRemark: true,
          tips: "为保证站内信息传播的客观真实，知乎会持续对社会谣言类不实信息进行处理。请在举报时提供政府机构、有关机构、权威媒体发布的辟谣信息或其他佐证，知乎将会核实处理，感谢你的支持。",
        },
      ],
    },
    {
      name: "垃圾广告",
      tips: "未经平台允许，知乎禁止使用帐号的任何功能，发布含有产品售卖信息、牟利性外链及违规推广等信息或引导用户至第三方平台进行交易。请在举报时简述理由，感谢你与我们共同维护社区的良好氛围。点击了解更多社区规范。",
    },
    {
      name: "辱骂、人身攻击等不友善行为",
      tips: "知乎鼓励友善的讨论。请在举报时提供对人产生辱骂、人身攻击或冒犯的相关内容段落或其他信息。感谢你与我们共同维护知乎的讨论氛围。点击了解更多社区规范。",
    },
    {
      name: "有害信息",
      submenus: [
        { name: "政治敏感" },
        { name: "色情低俗" },
        { name: "违法违规信息", tips: "遵纪守法，人人有责" },
        { name: "涉及封建迷信活动" },
        {
          name: "涉未成年人不良信息",
          tips: "为了快速定位并处置涉未成年人不良信息，保护未成年人身心健康，可通过本举报渠道对涉及内容进行举报。",
        },
      ],
    },
    { name: "不规范转载", requireRemark: true },
    {
      name: "诱导赞同、关注等行为",
      tips: "知乎禁止通过各种方式比如利益诱导（赞同、反对、分享行为后对用户有奖励）、威胁逼迫等形式干预用户在知乎站内的赞同、关注等，影响内容在站内正常流通。请在举报时简述理由，感谢你与我们共同维护社区氛围。点击了解更多社区规范。",
    },
    { name: "骚扰" },
    { name: "有人意图自杀或自残" },
  ];

  let [isOpen, setIsOpen] = useState(false);
  let [menus, setMenus] = useState(topMenus);
  let [selectedMenu, setSelectedMenu] = useState(null);
  let [reason, setReason] = useState("举报");
  let [description, setDescription] = useState("请选择举报理由");
  let [remark, setRemark] = useState("");
  let [showRemark, setShowRemark] = useState(false);
  let [history, setHistory] = useState([]);
  let [disabled, setDisable] = useState(true);

  const handleSubmit = async () => {
    const payload = { id: props.id, type: props.type, reason, remark };

    const resp = await post(`/api/v1/reports`, {
      contentType: "application/json",
      body: payload,
      headers: { Authorization: `Bearer ${props.token}` },
    });

    if (resp.ok) {
      handleClose()
      toast.success("提交成功");
    }
  };

  const handleClick = (idx) => {
    history.push({ reason, description, menus, showRemark });
    setSelectedMenu(menus[idx]);
    if (menus[idx].submenus) {
      setReason(menus[idx].name);
      setDescription("请选择举报理由");
      setMenus(menus[idx].submenus);
      setShowRemark(false);
    } else {
      setReason(menus[idx].name);
      setDescription(null);
      setShowRemark(true);
      setDisable(menus[idx].requireRemark);
    }
  };

  const goBack = () => {
    const menu = history.pop();
    setReason(menu.reason);
    setRemark("");
    setDescription(menu.description);
    setMenus(menu.menus);
    setShowRemark(menu.showRemark);
    setDisable(true);
  };

  const onRemarkChange = (evt) => {
    setRemark(evt.target.value);
    if (selectedMenu.requireRemark && evt.target.value.length > 0) {
      setDisable(false);
    }
  };

  const handleClose = () => {
    setReason("举报");
    setDescription("请选择举报理由");
    setRemark("");
    setIsOpen(false);
    setMenus(topMenus);
    setShowRemark(false);
    setHistory([]);
    setDisable(true);
  }

  return (
    <>
      <button
        className="border px-2 rounded-md"
        onClick={() => setIsOpen(!isOpen)}
      >
        举报
      </button>
      <ToastContainer position="top-center" autoClose={200} closeOnClick />
      <Dialog
        open={isOpen}
        onClose={handleClose}
        className="relative z-50"
      >
        <div className="fixed inset-0 flex items-center justify-center p-4 bg-black/20">
          <Dialog.Panel className="w-full max-w-sm rounded bg-white py-4 px-6">
            <Dialog.Title className="text-xl font-bold text-center">
              {reason}
            </Dialog.Title>
            {description && (
              <Dialog.Description className="text-sm text-gray-500 text-center">
                <div>{description}</div>
                <div className="text-xs">{props.id}</div>
              </Dialog.Description>
            )}

            {showRemark ? (
              <>
                <div className="my-2">
                  <textarea
                    value={remark}
                    onChange={onRemarkChange}
                    className="placeholder:text-slate-400 w-full border rounded-md p-2"
                    placeholder="详细说明(选填)"
                  />
                </div>
              </>
            ) : (
              <ul className="divide-y divide-dashed mt-2">
                {menus.map((menu, idx) => {
                  return (
                    <li
                      key={idx}
                      className="py-2 last:border-b-0 cursor-pointer"
                      onClick={() => {
                        handleClick(idx);
                      }}
                    >
                      {menu.name}
                    </li>
                  );
                })}
              </ul>
            )}

            <div className="flex space-x-2">
              {!isEmpty(history) && (
                <button
                  className="border border-black rounded-md py-2 w-full"
                  onClick={goBack}
                >
                  返回
                </button>
              )}
              <button
                onClick={handleSubmit}
                className={`border bg-black text-white rounded-md py-2 w-full ${
                  disabled ? "bg-black/70" : ""
                }`}
                disabled={disabled}
              >
                确认
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
});

export default ReportButton;
