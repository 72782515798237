import { Controller } from "@hotwired/stimulus"
import * as React from "react";
import { createRoot } from 'react-dom/client'
import Featured from  "../components/featured"

// Connects to data-controller="featured"
export default class extends Controller {
  static values = { token: String }

  connect() {
    createRoot(this.element).render(<Featured token={this.tokenValue}/>)
  }
}
