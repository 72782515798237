import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="pagy-align"
export default class extends Controller {
  static targets = [ "item" ]
  connect() {
    const items = document.getElementsByClassName("modal-image-item")
    if (items.length > 20) {
      const lastItem = items[items.length -1]
      lastItem.remove()
    }
  }
}
