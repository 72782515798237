import * as React from "react";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import useStore from './stores';
import Post from "./post";
import queryString from 'query-string'
import Remark from "./remark";
import _ from "lodash";

const Tipsoon = observer((props) => {
  const { sortingStore, authStore, postStore, uiStore } = useStore();
  const {answer, loading, notAuthorized, popTask, answerTask, setAnswer} = sortingStore;
  const {submiting, isDisable} = uiStore;
  const {post} = postStore;
  const {setToken} = authStore;

  useEffect(() => {
    let queries = queryString.parse(window.location.search)
    setToken(queries.token || props.token)

    popTask(null, true)
  }, [])

  const onSubmit = (e) => {
    const payload = { is_keep: answer.is_keep, qulaity: answer.qulaity, remark: answer.remark}
    answerTask(payload, true)
  }

  const handleKeep = (event) => {
    setAnswer({is_keep: true, qulaity: null, remark: null, reasons: []})
  }

  const handleNotKeep = (event) => {
    setAnswer({is_keep: false, qulaity: null, remark: null, reasons: []})
  }

  const onRemarkChange = (val) => {
    setAnswer({...answer, remark: val})
  }

  const onQualityChange = (event) => {
    setAnswer({...answer, qulaity: event.target.value})
  }

  if (notAuthorized) {
    return <div className="flex items-center justify-center h-screen text-gray-500">权限验证失败</div>
  }

  if (loading) {
    return <div className="flex items-center justify-center h-screen text-gray-500">加载中...</div>
  }

  return (
    <>
      <Post nextTask={() => {popTask(post.id, true)}} />
      <div className="rounded-md bg-slate-100 p-2">
        <div className="text-md">内容符合规范吗？</div>
        <div className="text-sm space-x-2 flex mt-2">
          <button onClick={handleKeep} className={`py-2 w-full border border-black rounded-md cursor-pointer hover:bg-black hover:text-white ${answer.is_keep === true ? 'bg-black text-white' : ''}`}>符合</button>
          <button onClick={handleNotKeep} className={`py-2 w-full border border-black rounded-md cursor-pointer hover:bg-black hover:text-white ${answer.is_keep === false ? 'bg-black text-white' : ''}`}>不符合</button>
        </div>
      </div>

      {
        answer.is_keep === true && (
          <>
            <div className="rounded-md bg-slate-100 p-2 mt-2">
              <div className="text-md">请给内容质量打分</div>
              <div className="text-sm space-x-2 flex my-2">
                <button onClick={onQualityChange} value="0" className={`py-2 w-full border border-black rounded-md cursor-pointer hover:bg-black hover:text-white ${answer.qulaity == 0 ? 'bg-black text-white' : ''}`}>普通</button>
                <button onClick={onQualityChange} value="1" className={`py-2 w-full border border-black rounded-md cursor-pointer hover:bg-black hover:text-white ${answer.qulaity == 1 ? 'bg-black text-white' : ''}`}>中等</button>
                <button onClick={onQualityChange} value="2" className={`py-2 w-full border border-black rounded-md cursor-pointer hover:bg-black hover:text-white ${answer.qulaity == 2 ? 'bg-black text-white' : ''}`}>优质</button>
              </div>
              <button onClick={onSubmit} disabled={!answer.qulaity || submiting } className={`py-2 w-full border rounded-md cursor-pointer ${answer.qulaity && !submiting ? 'bg-black border-black text-white' : 'bg-gray-500 border-gray-500 text-white cursor-not-allowed'}`}>{submiting ? '提交中...' : '提交'}</button>
            </div>

          </>
        )
      }

      {
        answer.is_keep === false && (
          <>
            <Remark onRemarkChange={onRemarkChange}/>
            <button onClick={onSubmit} disabled={isDisable} className={`py-2 w-full border border-black rounded-md cursor-pointer bg-black text-white ${isDisable ? 'bg-gray-500 border-gray-500 text-white cursor-not-allowed' : 'bg-black border-black text-white'}`}>{submiting ? '提交中...' : '提交'}</button>
          </>
        )
      }
    </>
  );
});

export default Tipsoon;
