import * as React from "react";
import { observer } from "mobx-react-lite";
import { Fragment, useState } from "react";
import { Dialog, Transition, Combobox } from "@headlessui/react";
import useStore from "./stores/";
import _ from "lodash";
import debounce from "debounce";

const addPostButton = observer((props) => {
  const [seriesId, setSeriesId] = useState(props.seriesId)
  const [selectedPost, setSelectedPost] = useState(null)
  let [isOpen, setIsOpen] = useState(false)
  const { autocompleteStore, seriesPostStore } = useStore()
  const { getPosts, posts } = autocompleteStore
  const { create } = seriesPostStore

  function closeModal() {
    setIsOpen(false);
    setSelectedPost(null);
  }

  const openModal = async () => {
    setIsOpen(!isOpen);
  }

  const onQuery = debounce(async (query) => {
    await getPosts(query, seriesId)
  }, 300)

  const onConfirm = async () => {
    if (selectedPost) {
      await create(seriesId, selectedPost.id)
      closeModal()
    }
  };

  return (
    <>
      <button
        type="button"
        onClick={openModal}
        className="bg-green-600/80 text-white hover:bg-green-600 rounded-md text-sm p-1 px-1.5 text-sm shadow-sm cursor-pointer"
      >
        添加条目
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 mb-2"
                  >
                    添加到合集
                  </Dialog.Title>
                  <div>
                    <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                      <Combobox value={selectedPost} onChange={setSelectedPost}>
                        <Combobox.Input
                          className="w-full border border-black/80 rounded-md py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                          onChange={(event) => onQuery(event.target.value)}
                          displayValue={(post) => post && post.title}
                        />
                        <Combobox.Options>
                          {posts.map((post) => (
                            /* Use the `active` state to conditionally style the active option. */
                            /* Use the `selected` state to conditionally style the selected option. */
                            <Combobox.Option key={post.id} value={post} as={Fragment}>
                              {({ active, selected }) => (
                                <li
                                  className={`relative cursor-default select-none py-2 pl-10 pr-4 ${ active ? 'bg-teal-600 text-white' : 'text-gray-900'}`}
                                >
                                  {post.title}

                                  {selected && <span>√</span>}
                                  <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`} >
                                    {selectedPost && selectedPost.title}
                                  </span>
                                </li>
                              )}
                            </Combobox.Option>
                          ))}
                        </Combobox.Options>
                      </Combobox>
                    </div>
                    <div className="flex space-x-2">
                      <button className="w-full border border-black py-1 rounded-md my-2" onClick={closeModal}>关闭</button>
                      <button className="w-full bg-black text-white py-1 rounded-md my-2" onClick={onConfirm}>提交</button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
})

export default addPostButton;