import { Controller } from "@hotwired/stimulus"
import * as React from "react";
import { createRoot } from 'react-dom/client'
import AddPostButton from "../components/addPostButton"

// Connects to data-controller="add-post-button"
export default class extends Controller {
  static values = { seriesId: String }

  connect() {
    createRoot(this.element).render(<AddPostButton seriesId={this.seriesIdValue}/>)
  }
}
