import * as React from "react";
import { observer } from "mobx-react-lite";
import useStore from './stores/';

const NextButton = observer((props) => {
  const handleSubmit = async () => {
    props.nextTask()
  }

  return (
    <button className="border px-2 rounded-md" onClick={handleSubmit}>跳过</button>
  );
});

export default NextButton;
