import { Controller } from "@hotwired/stimulus"
import * as React from "react";
import { createRoot } from 'react-dom/client'
import Proofread from  "../components/proofread"

// Connects to data-controller="proofread"
export default class extends Controller {
  static values = { token: String }

  connect() {
    createRoot(this.element).render(<Proofread token={this.tokenValue}/>)
  }
}
