import * as React from "react";
import { observer } from "mobx-react-lite";
import { Fragment, useState, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Dialog, Transition } from "@headlessui/react";
import useStore from "./stores/";
import _ from "lodash";

const PicturePicker = observer((props) => {
  const fileInputRef = useRef();
  const [previewUrl, setPreviewUrl] = useState(props.url);
  const [imageId, setImageId] = useState(props.imageId);
  const [imageEle, setImageEle] = useState(props.imageEle);
  const [selectedFile, setSelectedFile] = useState();
  const { imageStore, postStore, uiStore } = useStore();
  const [selectedImage, setSelectedImage] = useState(null);
  const { post, update } = postStore;
  const { isDisable, submiting } = uiStore;
  const { images, saveUnsplash, uploadImages, getImages, page, uploading } = imageStore;

  let [isOpen, setIsOpen] = useState(false);
  let [showUnsplashForm, setShowUnsplashForm] = useState(false);
  let [unsplashUrl, setUnsplashUrl] = useState("");

  function closeModal() {
    setIsOpen(false);
    setSelectedImage(null);
  }

  const openModal = async () => {
    await getImages();
    setIsOpen(!isOpen);
  };

  const handleSelected = (evt, image) => {
    Array.from(document.querySelectorAll(".media_picker_selected")).forEach(
      (el) => el.classList.remove("media_picker_selected")
    );
    evt.target.classList.toggle("media_picker_selected");

    setSelectedImage(image);
  };

  const handleConfirm = async () => {
    if (selectedImage) {
      const imageIdEle = document.getElementById(imageEle)
      if (imageIdEle) {
        imageIdEle.value = selectedImage.id
        setPreviewUrl(selectedImage.url)
        closeModal();
      } else {
        toast.error("找不到图片元素");
      }
    }
  };

  const toggleUnsplash = () => {
    setShowUnsplashForm(!showUnsplashForm);
  };

  const closeUnsplash = () => {
    setShowUnsplashForm(false);
    setUnsplashUrl("");
  };

  const confirmUnsplash = async () => {
    await saveUnsplash(unsplashUrl);
    setUnsplashUrl("");
  };

  const uploadFromLocal = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async () => {
    await uploadImages(fileInputRef.current.files)
  };

  const clear = async () => {
    const imageIdEle = document.getElementById(imageEle)
    if (imageIdEle) {
      imageIdEle.value = null
      setPreviewUrl("")
      // 发送删除图片请求
    } else {
      toast.error("找不到图片元素");
    }
  }

  return (
    <>
      {
        _.isEmpty(previewUrl) ? (
          <button
            type="button"
            onClick={openModal}
            className="rounded-md bg-black px-2 py-0.5 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
          >
            选择图片
          </button>
        ) : (
          <div className="relative">
            <img src={previewUrl} className="w-24 h-16 rounded-lg cursor-pointer object-cover" onClick={openModal}/>
            <span className="absolute top-0 right-0 p-1">
              <button type="button" onClick={clear} className="rounded-md px-2 py-1 shadow hover:bg-red-700 bg-red-500 text-white text-xs">移除</button>
            </span>
          </div>
        )
      }
      <ToastContainer position="top-center" autoClose={200} closeOnClick />
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 mb-2"
                  >
                    选择图片
                  </Dialog.Title>
                  <div>
                    <div className="flex justify-between">
                      <div className="space-x-2">
                        <button
                          type="button"
                          className="px-2 rounded-md bg-black text-white"
                          onClick={toggleUnsplash}
                        >
                          Unsplash
                        </button>

                        <button type="button" className="px-2 rounded-md bg-blue-600 text-white">Wikipedia</button>
                        <button
                          className={`px-2 rounded-md bg-red-600 text-white ${uploading ? "cursor-not-allowed" : ""}`}
                          disabled={uploading}
                          onClick={uploadFromLocal}
                        >
                          { uploading ? '上传中...' : '本地上传'}
                        </button>
                        <input
                          multiple={true}
                          accept="image/*"
                          className="hidden"
                          type="file"
                          ref={fileInputRef}
                          onChange={handleFileChange}
                          name="files"
                        />
                      </div>
                      <div>
                      <button
                        className={`px-2 rounded-md text-white ${
                          !_.isEmpty(selectedImage) ? "bg-black" : "bg-gray-600"
                        }`}
                        onClick={handleConfirm}
                      >
                        确认
                      </button>
                      </div>
                    </div>

                    {showUnsplashForm && (
                <div className="my-2">
                  <div>
                    <input
                      value={unsplashUrl}
                      onChange={(evt) => {
                        setUnsplashUrl(evt.target.value);
                      }}
                      type="text"
                      className="border rounded-md px-2 py-1 w-full"
                      placeholder="https://unsplash.com/photos/..."
                    />
                  </div>
                  <div className="flex space-x-2 my-1">
                    <button
                      className="border px-2 rounded-md w-full"
                      onClick={closeUnsplash}
                    >
                      取消
                    </button>
                    <button
                      className={`px-2 rounded-md text-white w-full ${
                        _.isEmpty(unsplashUrl)
                          ? "bg-gray-600 cursor-not-allowed"
                          : "bg-black cursor-pointer"
                      }`}
                      disabled={_.isEmpty(unsplashUrl)}
                      onClick={confirmUnsplash}
                    >
                      {submiting ? "保存中..." : "保存"}
                    </button>
                  </div>
                </div>
              )}

                    <div className="grid grid-cols-5 gap-4 py-4">
                      {images.map((image, idx) => {
                        return (
                          <img
                            src={image.url || image.webp_url}
                            className="w-24 h-24 cursor-pointer"
                            onClick={(evt) => {
                              handleSelected(evt, image);
                            }}
                            key={idx}
                          />
                        );
                      })}
                    </div>
              <div className="flex justify-center space-x-2 text-sm">
                {page.prev > 0 && (
                  <button
                    className="bg-gray-100 hover:bg-gray-200 rounded-lg p-1"
                    onClick={() => {
                      getImages(page.prev);
                    }}
                  >
                    上一页
                  </button>
                )}
                {page.next > 0 && (
                  <button
                    className="bg-gray-100 hover:bg-gray-200 rounded-lg p-1"
                    onClick={() => {
                      getImages(page.next);
                    }}
                  >
                    下一页
                  </button>
                )}
              </div>

                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
})

export default PicturePicker;