import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js";
import Tagify from '@yaireo/tagify';

// Connects to data-controller="tags-select"
export default class extends Controller {
  static targets = [ "output" ]
  static values = { url: String }


  connect() {
    this.tagify = new Tagify(this.outputTarget, {
      maxTags: 10,
    });

    this.tagify.on('input', this.onInput.bind(this))
  }

  async onInput(e) {

    this.tagify.whitelist = null;
    this.tagify.loading(true)

    const query = e.detail.value

    const resp = await get(this.urlValue + encodeURIComponent(query), {
      contentType: 'application/json',
      responseKind: 'json',
    })
    if (resp.ok) {
      const tags = await resp.response.json()
      this.tagify.settings.whitelist = tags

      this.tagify.loading(false).dropdown.show(query)
    }
  }

  addTag() {
    this.tagify.addTags("中国")
  }
}

