import * as React from "react";
import { observer } from 'mobx-react-lite'
import useStore from './stores'
import { isEmpty } from "lodash";

const Post2 = observer((props) => {
  const post = props.post
  const { authStore } = useStore()
  const {token} = authStore

  if (isEmpty(post)) {
    return <div className="my-2 rounded-md bg-slate-100 p-1 py-4 text-center ">暂无内容</div>
  }

  return (
    <div className="my-2">
      <div className="rounded-md space-y-2">
        {/* {
          (post.images && post.images.length > 0) && (
            <div className="flex justify-center items-center relative h-[260px] md:h-[400px] w-full overflow-hidden rounded-md">
            <img  className="z-0 absolute left-0 top-0 object-cover w-full" src={`${post.images[0].gaussblur_url || post.images[0].url}`} />
            <img  className="h-[260px] md:h-[400px] absolute" src={`${post.images[0].webp_url || post.images[0].url}`} />
          </div>
          )
        } */}

        <div className="rounded-md bg-slate-100 p-1">
          <div className="font-bold text-lg">{post.title}</div>
          <div className="text-xs text-gray-600 flex items-center justify-between">
            <span>{post.author_s}</span>
            <span className="space-x-1">
              {!isEmpty(post.source_from) && (<span className="border rounded-md px-2">{post.source_from[0]}</span>)}
              <span className="border rounded-md px-2">{post.format == 'plain' ? '纯文本' : '富文本'}</span>
              {post.is_quote ? <span className="border rounded-md px-2">金句</span> : <span className="border rounded-md px-2">普通</span>}
            </span>
          </div>
          {(post.format === 'plain') && (<div className="text-md text-black/90" style={{whiteSpace: "pre-wrap"}}>{post.content}</div>)}
          {(post.format === 'html') && (<div className="octopus-content" dangerouslySetInnerHTML={ { __html: post.content_html}} ></div>)}
        </div>

        {
          !isEmpty(post.remark) && (
            <div className="rounded-md bg-orange-100 p-1 space-y-0.5 text-xs text-orange-600">
              <span className="font-bold">备注：</span>
              {post.remark}
            </div>
          )
        }
        {/* {
          !isEmpty(post.metadata) && (
            <div className="rounded-md bg-purple-100 p-1 space-y-0.5 text-xs text-purple-600">
              <ul>
                {
                  Object.entries(post.metadata).map(([key, value], idx) => {
                    if (key === 'cover') {
                      return <li key={idx}><img src={value} className="w-20 h-20"/></li>
                    } else {
                      return <li key={idx} className="x-1"><b>{key}: </b>{value}</li>
                    }

                  })
                }
              </ul>
            </div>
          )
        } */}
      </div>
    </div>
  )
})

export default Post2;
