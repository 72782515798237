import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

// Connects to data-controller="image-picker"
export default class extends Controller {
  static targets = ["confirmButton", "imageItem"]
  static values = { fieldName: String, isMultiple: Boolean }

  connect() {
    if (this.isMultipleValue) {
      this.selectedImageIds = []
    } else {
      this.selectedImageId = null
    }
  }

  async confirm(evt) {
    const payload = {}

    if (this.isMultipleValue) {
      payload.image_ids = this.selectedImageIds,
      payload.field_name = this.fieldNameValue
    } else {
      payload.image_id = this.selectedImageId
      payload.field_name = this.fieldNameValue
    }

    const resp = await post('/admin/media_picker', {
      body: payload,
      contentType: 'application/json',
      responseKind: 'turbo-stream',
    })

    if (resp.ok) {
      this.modalTurboFrame.removeChild(this.modalTurboFrame.firstChild)
    }
  }

  addImage(evt) {
    if (this.isMultipleValue) {
      this.mutipleSelect(evt)
    } else {
      this.singleSelect(evt)
    }
  }

  singleSelect(evt) {
    // 移除所有选中状态
    this.imageItemTargets.forEach(option => {
      option.classList.remove("border")
      option.classList.remove("border-2")
      option.classList.remove("border-red-500")
    })

    // 新增当前选中状态
    evt.target.classList.toggle("border")
    evt.target.classList.toggle("border-2")
    evt.target.classList.toggle("border-red-500")


    this.selectedImageId = evt.target.dataset.imageId

    // 更新按钮状态
    if (this.selectedImageId) {
      this.confirmButtonTarget.classList.remove("bg-gray-500", "cursor-not-allowed")
      this.confirmButtonTarget.classList.add("bg-black")
      this.confirmButtonTarget.disabled = false
    } else {
      this.confirmButtonTarget.disabled = true
      this.confirmButtonTarget.classList.remove("bg-black")
      this.confirmButtonTarget.classList.add("bg-gray-500", "cursor-not-allowed")
    }
  }

  mutipleSelect(evt) {
    // 选中状态
    evt.target.classList.toggle("border")
    evt.target.classList.toggle("border-2")
    evt.target.classList.toggle("border-red-500")

    // Add image id to selectedImageIds
    const index = this.selectedImageIds.findIndex(imageId => {
      if (imageId.toString() === evt.target.dataset.imageId) { return true }
    })

    if (index !== -1) {
      this.selectedImageIds.splice(index, 1);
    } else {
      this.selectedImageIds.push(evt.target.dataset.imageId)
    }

    // 更新按钮状态
    if (this.selectedImageIds.length > 0) {
      this.confirmButtonTarget.classList.remove("bg-gray-500", "cursor-not-allowed")
      this.confirmButtonTarget.classList.add("bg-black")
      this.confirmButtonTarget.disabled = false
    } else if (this.selectedImageIds.length === 0) {
      this.confirmButtonTarget.disabled = true
      this.confirmButtonTarget.classList.remove("bg-black")
      this.confirmButtonTarget.classList.add("bg-gray-500", "cursor-not-allowed")
    }
  }


  get modalTurboFrame() {
    const modal = document.querySelector("turbo-frame[id='modal']")

    if (!modal) {
      return document.querySelector("turbo-frame[id='dialog']")
    } else {
      return modal
    }
  }
}
