import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ["link"]

  static values = { url: String }

  fetch(event) {
    event.preventDefault()

    let url = ''
    if(this.linkTarget.textContent.indexOf('时间') != -1) {
      this.linkTarget.textContent = '切换为默认排序'
      url = `${this.urlValue}?sort=created_desc`
    } else if (this.linkTarget.textContent.indexOf('默认') != -1) {
      this.linkTarget.textContent = "​切换为时间排序"
      url = this.urlValue
    }

    get(url, {
      headers: {"Turbo-Frame": "post_comments"},
      responseKind: "turbo-stream"
    })
  }
}
