import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = ["item"]

  connect() {
    this.timeout = setTimeout((() => {
      this.close()
    }).bind(this), 1500)
  }

  close(e) {
    clearTimeout(this.timeout)
    this.timeout = null
    let parent = this.element.parentElement
    parent.innerHTML = ""
  }

  disconnect() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }
}
