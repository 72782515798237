import * as React from "react";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import useStore from './stores';
import Post from "./post";
import queryString from 'query-string'

const Featured = observer((props) => {
  const { featuredStore, postStore, authStore } = useStore();
  const {answer, loading, submiting, notAuthorized, noMoreQuiz, popTask, answerTask, setAnswer} = featuredStore;
  const {post} = postStore;
  const {setToken} = authStore;

  useEffect(() => {
    let queries = queryString.parse(window.location.search)
    setToken(queries.token || props.token)

    popTask()
  }, [])

  const onSubmit = (e) => {
    const payload = { is_excellent: answer.is_excellent, is_bootstrap: answer.is_bootstrap }
    answerTask(payload)
  }

  const handleFeatured = (event) => {
    setAnswer({is_excellent: true, is_bootstrap: null})
  }

  const handleNotFeatured = (event) => {
    setAnswer({is_excellent: false, is_bootstrap: null})
    popTask(post.id)
  }

  const allowBootstrap = (event) => {
    setAnswer({...answer, is_bootstrap: true})
  }

  const notAllowBootstrap = (event) => {
    setAnswer({...answer, is_bootstrap: false})
  }

  if (notAuthorized) {
    return <div className="flex items-center justify-center h-screen text-gray-500">权限验证失败</div>
  }

  if (loading) {
    return <div className="flex items-center justify-center h-screen text-gray-500">加载中...</div>
  }

  if (noMoreQuiz) {
    return (
      <div className="flex items-center justify-center h-screen w-full">
        <div className="flex-col items-center justify-center space-y-2">
          <div className="text-gray-500">暂无任务</div>
          <div className="text-center">
            <button className="bg-black text-white rounded-md px-2 py-1/2 text-sm" onClick={() => {popTask()}}>重试</button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <Post nextTask={() => {popTask(post.id)}} showReport={true} />
      <div className="rounded-md bg-slate-100 p-2">
        <div className="text-md">可以添加精华吗?</div>
        <div className="text-sm space-x-2 flex mt-2">
          <button onClick={handleFeatured} className={`py-2 w-full border border-black rounded-md cursor-pointer hover:bg-black hover:text-white ${answer.is_excellent === true ? 'bg-black text-white' : ''}`}>可以</button>
          <button onClick={handleNotFeatured} className={`py-2 w-full border border-black rounded-md cursor-pointer hover:bg-black hover:text-white ${answer.is_excellent === false ? 'bg-black text-white' : ''}`}>不可以</button>
        </div>
      </div>

       {
        answer.is_excellent === true && (
          <>
            <div className="rounded-md bg-slate-100 p-2 mt-2">
              <div className="text-md">适合作为冷启动数据吗?</div>
              <div className="text-sm space-x-2 flex my-2">
                <button onClick={allowBootstrap} className={`py-2 w-full border border-black rounded-md cursor-pointer hover:bg-black hover:text-white ${answer.is_bootstrap === true ? 'bg-black text-white' : ''}`}>可以</button>
                <button onClick={notAllowBootstrap} className={`py-2 w-full border border-black rounded-md cursor-pointer hover:bg-black hover:text-white ${answer.is_bootstrap === false ? 'bg-black text-white' : ''}`}>不可以</button>
              </div>
              <button onClick={onSubmit} disabled={submiting} className={`py-2 w-full border rounded-md cursor-pointer ${answer.is_bootstrap !== null && !submiting ? 'bg-black border-black text-white' : 'bg-gray-500 border-gray-500 text-white'}`}>{submiting ? '提交中...' : '提交'}</button>
            </div>
          </>
        )
      }
    </>
  );
});

export default Featured;
