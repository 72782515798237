import * as React from "react";
import { observer } from "mobx-react-lite";
import useStore from './stores/';

const SpeakButton = observer((props) => {
  const [isPlaying, setIsPlaying] = React.useState(false);
  const { postStore } = useStore();
  const {post} = postStore;

  const play = (text) => {
    if (isPlaying) {
      window.speechSynthesis.cancel()
      setIsPlaying(false)
    } else {
      window.speechSynthesis.cancel()
      const utterance = new SpeechSynthesisUtterance(text)
      utterance.addEventListener('end', () => {
        setIsPlaying(false)
      });
      utterance.rate = 1.8
      window.speechSynthesis.speak(utterance)
      setIsPlaying(true)
    }
  }

  return (
    <button className={`border px-2 rounded-md ${isPlaying ? 'text-green-600' : ''}`} onClick={() => { play(post.content) }}>{isPlaying ? '停止' : '朗读'}</button>
  );
});

export default SpeakButton;
