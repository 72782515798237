import { makeAutoObservable } from "mobx";
import { get, post } from "@rails/request.js";
import { toast } from "react-toastify";
import _ from "lodash";

class AuthStore {
  token = "";
  currentModerator = {};
  moderators = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
    // 会把所有的属性变为 observable、所有的方法变为 action、所有 get 修饰的变为计算属性
    // autoBind true 表示绑定函数内部的 this 就是实例
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setToken(val) {
    this.token = val;
  }

  setCurrentModerator(val) {
    this.currentModerator = val;
  }

  async getModerators() {
    this.loading = true;
    const payload = {
      headers: { Authorization: `Bearer ${this.rootStore.authStore.token}` }
    }
    const resp = await get("/censor/users/moderators", payload);
    if (resp.ok) {
      this.loading = false;

      const result = await resp.json;
      this.moderators = result.map((item) => { return { value: item.id, label: item.screen_name } });
      this.currentModerator = this.moderators[0]
    } else {
      console.log(resp);
      // if (resp.statusCode == 400) {
      //   const result = await resp.json;
      //   if (result.type && result.type == "no_more_quiz") {
      //     this.loading = false;
      //     this.noMoreQuiz = true;
      //   }
      // } else if (resp.statusCode == 401) {
      //   this.notAuthorized = true;
      //   this.loading = false;
      // } else if (resp.statusCode == 403) {
      //   const result = await resp.json;
      //   toast.error(result.message);
      // } else {
      //   const result = await resp.json;
      //   toast.error(result.message);
      // }
    }
  }
}

export default AuthStore;
