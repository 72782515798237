import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js";
import SlimSelect from 'slim-select'

export default class extends Controller {
  connect() {
    this.select = new SlimSelect({
      select: this.element,
      placeholder: "请用合集名称检索",
      searchingText: 'Searching...',
      limit: 2,
      ajax: async (search, callback) => {
        if (search.length < 2) {
          callback('请输入至少2个字符')
          return
        }
        const url = `/admin/series/autocomplete?q=${encodeURIComponent(search)}`
        console.log(url)
        const resp = await get(url , {
          contentType: 'application/json',
          responseKind: 'json',
        })
        if (resp.ok) {
          const data = await resp.response.json()
          let series = data.map((tag) => {
            return { value: tag[1], text: tag[0] }
          })

          callback(series)
        }
      }
    })
  }

  disconnect() {
    this.select.destroy()
  }
}

