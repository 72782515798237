import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js";

// Connects to data-controller="reply"
export default class extends Controller {
  static values = { url: String }

  connect() {
  }

  async check() {
    const resp = await post(this.urlValue, {
      contentType: 'application/json',
      responseKind: 'turbo-stream',
    })
  }
}
