import { makeAutoObservable } from "mobx";
import { get } from "@rails/request.js";
import { toast } from "react-toastify";
import _ from "lodash";

class AutocompleteStore {
  seriesList = [];
  posts = [];
  notAuthorized = false;

  constructor(rootStore) {
    this.rootStore = rootStore;
    // 会把所有的属性变为 observable、所有的方法变为 action、所有 get 修饰的变为计算属性
    // autoBind true 表示绑定函数内部的 this 就是实例
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async getSeries(query, postId = null) {
    const payload = {
      headers: { Authorization: `Bearer ${this.rootStore.authStore.token}` }
    }

    let query_params = {}
    query_params.q = query
    if (postId) { query_params.post_id = postId }
    payload.query = query_params

    const resp = await get("/autocomplete/series", payload);
    if (resp.ok) {
      const result = await resp.json;
      this.seriesList = result
    } else {
      if (resp.statusCode == 401) {
        this.notAuthorized = true;
      } else if (resp.statusCode == 403) {
        const result = await resp.json;
        toast.error(result.message);
      } else {
        const result = await resp.json;
        toast.error(result.message);
      }
    }
  }

  async getPosts(query, seriesId = null) {
    const payload = {
      headers: { Authorization: `Bearer ${this.rootStore.authStore.token}` }
    }

    let query_params = {}
    query_params.q = query
    if (seriesId) { query_params.series_id = seriesId }
    payload.query = query_params

    const resp = await get("/autocomplete/posts", payload);
    if (resp.ok) {
      const result = await resp.json
      this.posts = result
    } else {
      if (resp.statusCode == 401) {
        this.notAuthorized = true;
      } else if (resp.statusCode == 403) {
        const result = await resp.json;
        toast.error(result.message);
      } else {
        const result = await resp.json;
        toast.error(result.message);
      }
    }
  }
}

export default AutocompleteStore;