import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="empty-state"
export default class extends Controller {
  static targets = [ "wrapper", "pagy", "item", "holder" ]

  itemTargetConnected() {
    if (this.itemTargets.length > 0) {
      if (this.hasHolderTarget) {
        this.holderTarget.remove()
      }
    }
  }

  itemTargetDisconnected() {
    if (this.itemTargets.length == 0) {
      if (this.hasPagyTarget) {
        this.pagyTarget.remove()
      }

      var newDiv = document.createElement("div")
      newDiv.setAttribute("id", "empty-state")
      newDiv.setAttribute("class", "border rounded-md my-2 py-10 text-center text-gray-600 bg-white")
      newDiv.setAttribute("data-empty-state-target", "holder")
      let newContent = document.createTextNode("暂无内容")
      newDiv.appendChild(newContent)

      this.wrapperTarget.appendChild(newDiv)
    }
  }
}
