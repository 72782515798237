import * as React from "react";
import { observer } from 'mobx-react-lite'
import useStore from '../stores'
import Editor from  "../../components/editor2"

const EditForm = observer((props) => {
  const { postStore } = useStore()
  const { post, update } = postStore
  const [title, setTitle] = React.useState(post.title || '')
  const [author, setAuthor] = React.useState(post.author || '')
  const [originalUrl, setOriginalUrl] = React.useState(post.original_url || '')

  const [format, setFormat] = React.useState(post.format)
  const [content, setContent] = React.useState(post.content)
  const [contentHtml, setContentHtml] = React.useState(post.content_html)
  const [contentJson, setContentJson] = React.useState(post.content_json)

  const onSubmit = () => {
    let payload = { title, author, content, format, original_url: originalUrl, content_html: contentHtml, content_json: contentJson, re_edited: true }

    update(post.id, payload)
  }

  const onUpdate = (editor) => {
    const formatInput = document.getElementById('post_format')
    setFormat(formatInput.value)

    if (formatInput.value === 'html') {
      setContent(editor.getText())
      setContentHtml(editor.getHTML())
      setContentJson(JSON.stringify(editor.getJSON()))
    } else if (formatInput.value === 'plain') {
      setContent(editor.getText())
      setContentHtml(null)
      setContentJson(JSON.stringify(editor.getJSON()))
    }
  }

  return (
    <div className="rounded-md bg-slate-100 p-1 space-y-1.5">
      <div>
        <input placeholder="标题" type="text" name="title" value={title} onChange={(e) => {setTitle(e.target.value)}} className="border rounded-md text-sm p-2 w-full" />
      </div>
      <div>
        <input placeholder="作者" type="text" name="author" value={author} onChange={(e) => {setAuthor(e.target.value)}} className="border rounded-md text-sm p-2 w-full" />
      </div>
      <div>
        <Editor format={post.format} contentPlain={post.content} contentHtml={post.content_html} contentJson={post.content_json} onUpdate={onUpdate} />
      </div>
      <div>
        <input placeholder="来源(选填)" type="text" name="original_url" value={originalUrl} onChange={(e) => {setOriginalUrl(e.target.value)}} className="border rounded-md text-sm p-2 w-full" />
      </div>
      <div>
        <button className="bg-black text-white py-2 rounded-md w-full" onClick={() => { onSubmit() }}>保存</button>
      </div>

      <input value='html' autoComplete="off" type="hidden" name="format" id="post_format" />
    </div>
  )
})

export default EditForm
