import { makeAutoObservable } from "mobx";
import { get, post, destroy } from "@rails/request.js";
import { toast } from "react-toastify";
import _ from "lodash";

class SortingStore {
  loading = true;
  answer = {is_keep: null, qulaity: null, remark: null, reasons: []};

  // noMoreTask = false;
  // notAuthorized = false;

  constructor(rootStore) {
    this.rootStore = rootStore;
    // 会把所有的属性变为 observable、所有的方法变为 action、所有 get 修饰的变为计算属性
    // autoBind true 表示绑定函数内部的 this 就是实例
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async popTask(pass_id = null, tipsoon_mode = false) {
    this.loading = true;
    const payload = {
      headers: { Authorization: `Bearer ${this.rootStore.authStore.token}` }
    }

    let query_params = {}
    if (pass_id) { query_params.pass_id = pass_id }
    if (tipsoon_mode) { query_params.tipsoon_mode = tipsoon_mode }
    payload.query = query_params

    const resp = await get("/censor/sorting/pop", payload);
    if (resp.ok) {
      this.loading = false;

      const result = await resp.json;
      this.rootStore.postStore.post = result;
      this.answer = {is_keep: null, qulaity: null, remark: null, reasons: []}
    } else {
      if (resp.statusCode == 401) {
        this.notAuthorized = true;
        this.loading = false;
      } else if (resp.statusCode == 403) {
        const result = await resp.json;
        toast.error(result.message);
      } else {
        const result = await resp.json;
        toast.error(result.message);
      }
    }
  }

  async answerTask(params, tipsoon_mode = false) {
    this.rootStore.uiStore.setSubmiting(true);
    this.rootStore.uiStore.setIsDisable(true);
    const payload = {
      headers: { Authorization: `Bearer ${this.rootStore.authStore.token}` },
      body: params,
      contentType: "application/json",
    }

    const resp = await post(`/censor/sorting/${this.rootStore.postStore.post.id}/answer`, payload);
    if (resp.ok) {
      this.rootStore.uiStore.setSubmiting(false);
      this.rootStore.uiStore.setIsDisable(false);
      if (tipsoon_mode) {
        this.popTask(null, true)
      } else {
        this.popTask()
      }
      this.rootStore.remarkStore.setRemark('');
      this.rootStore.remarkStore.setRemarks([]);
    } else {
      this.rootStore.uiStore.setSubmiting(false);
      this.rootStore.uiStore.setIsDisable(false);
      if (resp.statusCode == 401) {
        this.notAuthorized = true;
        this.loading = false;
      } else if (resp.statusCode == 403) {
        const result = await resp.json;
        toast.error(result.message);
      } else {
        const result = await resp.json;
        toast.error(result.message);
        if (result.type === 'already_done') {
          if (tipsoon_mode) {
            this.popTask(null, true)
          } else {
            this.popTask()
          }
        }
      }
    }
  }

  setAnswer(val) {
    this.answer = val;
  }
}

export default SortingStore;

